"use client";

import { FC, useState } from "react";
import { Button } from "@gs-ux-uitoolkit-react/button";
import { useStyleSheet, StyleSheet } from "@gs-ux-uitoolkit-react/style";
import ReportFollowModal from "../FollowModal/index.tsx";
import { buttonClasses } from "./styles.ts";
import { Tag } from "../types.ts";

type Props = {
    tags: Tag[];
};

const ReportFollowButton: FC<Props> = ({ tags }) => {
    const [visible, setVisible] = useState(false);

    const toggleVisibility = () => setVisible(!visible);

    const overrideStyleSheet = new StyleSheet("button-override", {
        root: { display: "flex !important" },
    });
    const overrideClasses = useStyleSheet(overrideStyleSheet, null);

    return (
        <>
            <Button
                classes={{ root: overrideClasses.root }}
                data-testid="follow-button"
                className={buttonClasses}
                onClick={toggleVisibility}
                actionType="primary"
                emphasis="bold"
                style={{ marginRight: "8px" }}>
                Follow
            </Button>
            <ReportFollowModal tags={tags} placement="center" visible={visible} toggleVisibility={toggleVisibility} />
        </>
    );
};

export default ReportFollowButton;
