"use client";

import { NavigationBarItem } from "@forge-cms/models/components";
import { useState } from "react";
import { HeaderProps } from "./types.ts";

type NavBarMenuProps = {
    items: NavigationBarItem[];
    myContent?: {
        link: string;
        items: NavigationBarItem[];
    };
    className?: string;
};

type SubNavBarMenuProps = {
    items: NavigationBarItem[];
    subItemClassName?: string;
};

type NavBarItemProps = {
    item: NavigationBarItem;
    itemClassName?: string;
};

const SubNavBarItem = ({ item, itemClassName }: NavBarItemProps) => (
    <li className={`hover:cursor-pointer py-[5px] px-[16px] hover:bg-[#e4e4e4] ${itemClassName || ""}`}>
        <a
            href={item.link}
            className="!no-underline font-sans text-[15px] text-[#29323a] hover:text-[#29323a] visited:text-[#29323a]">
            {item.name}
        </a>
    </li>
);

const SubNavBarMenu = ({ items, subItemClassName }: SubNavBarMenuProps) => (
    <ul className="absolute bg-white min-w-[250px] left-[0px] mt-[7.5px] border border-[#e4e4e4]">
        {items.map((item) => (
            <SubNavBarItem item={item} itemClassName={subItemClassName} />
        ))}
    </ul>
);

const NavBarItem = ({ item, itemClassName }: NavBarItemProps) => {
    const [showChildItems, setShowChildItems] = useState(false);

    return (
        <li
            className={`group relative hover:cursor-pointer border-b-[3px] border-b-transparent hover:border-[#186ade] ${
                itemClassName || ""
            }`}
            onMouseEnter={() => setShowChildItems(true)}
            onMouseLeave={() => setShowChildItems(false)}>
            <a
                href={item.link}
                className="uppercase !no-underline font-bold font-sans text-[15px] text-[#29323a] visited:text-[#29323a] group-hover:text-[#186ade]">
                {item.name}
            </a>
            {item.items?.length > 0 && showChildItems && (
                <SubNavBarMenu items={item.items} subItemClassName={itemClassName} />
            )}
        </li>
    );
};

const NavBarMenu = ({ items, myContent, className }: NavBarMenuProps) => (
    <ul className={`flex flex-row flex-wrap ${className || ""}`}>
        {myContent && (
            <>
                <NavBarItem
                    item={{
                        name: "My Content",
                        items: myContent.items,
                        link: myContent.link,
                    }}
                    itemClassName="py-[5px] pr-[16px]"
                />
                <span className="font-light	text-[#cccccc] text-[20px]">|</span>
            </>
        )}
        {items.map((item) => (
            <NavBarItem item={item} itemClassName="py-[5px] px-[16px] " />
        ))}
    </ul>
);

const DefaultHeader = ({
    navItems,
    myContentNavItems,
    myContentLink,
    logo,
    searchBar,
    userProfile,
    className,
}: HeaderProps) => {
    const myContent = myContentLink
        ? {
              items: myContentNavItems,
              link: myContentLink!,
          }
        : undefined;

    return (
        <div className={`bg-white top-0 w-full z-50 border-b border-[#e4e4e4] md:px-0 sm:px-4 ${className || ""}`}>
            <div className="my-0 mx-auto min-h-10 pt-2 md:w-[1023px] lg:w-[1190px] md:inset-x-0">
                <div className="flex flex-row">
                    {logo}
                    <div className="flex flex-grow flex-row gap-x-1 justify-end space-x-1 content-center text-center">
                        {userProfile}
                        {searchBar}
                    </div>
                </div>
                <NavBarMenu items={navItems} myContent={myContent} className="mt-2" />
            </div>
        </div>
    );
};

export default DefaultHeader;
