import { LogContext } from "@forge-ui-components/tracing";
import { tracer } from "@forge-ui-components/tracing-client";
import { Interest, Tag } from "./types.ts";

const LOG_CONTEXT: LogContext = { moduleName: "TagAndIntrestDataLoader" };

type ErrorHandler = (isError: boolean) => void;

const getJwt = async (): Promise<string | undefined> => (window as any).JwtService?.getJwt();

export const getInterestDetailsForTag = async (tags: Tag[], handleError: ErrorHandler) => {
    const url = "/funl/tags/interests";
    const jwt = await getJwt();

    try {
        const response = await fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                ...(jwt && { Authorization: `Bearer ${jwt}` }),
            },
            body: JSON.stringify(tags),
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            const { status } = response;
            throw new Error(`Post request failed with status: ${status} and error: ${errorMessage}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        tracer.log.error(`${error}`, LOG_CONTEXT);
        handleError(true);
        return Promise.reject(error);
    }
};

export const putInterest = async (apiUrl: string, interests: Interest[] | Interest, handleError: ErrorHandler) => {
    const jwt = await getJwt();

    try {
        const response = await fetch(apiUrl, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                ...(jwt && { Authorization: `Bearer ${jwt}` }),
            },
            body: JSON.stringify(interests),
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            const { status } = response;
            throw new Error(`Post request failed with status: ${status} and error: ${errorMessage}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        tracer.log.error(`${error}`, LOG_CONTEXT);
        handleError(true);
        return Promise.reject(error);
    }
};

export const removeInterestData = async (interestId: string | undefined, handleError: ErrorHandler) => {
    const url = `/funl/interests/${interestId}`;
    const jwt = await getJwt();

    try {
        const response = await fetch(url, {
            method: "DELETE",
            credentials: "include",
            headers: {
                ...(jwt && { Authorization: `Bearer ${jwt}` }),
            },
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            const { status } = response;
            throw new Error(`Post request failed with status: ${status} and error: ${errorMessage}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        tracer.log.error(`${error}`, LOG_CONTEXT);
        handleError(true);
        return Promise.reject(error);
    }
};
