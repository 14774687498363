import React from "react";
import classNames from "classnames";

export type TagProps = {
    title: string;
    value: string;
    size: "sm" | "md";
};

const Tag: React.FC<TagProps> = ({ title, value, size }: TagProps) => {
    const styles = classNames({
        "font-normal text-black mb-[10px] mr-[10px] no-underline overflow-hidden truncate  inline-block rounded-full bg-surface-neutral-regular hover:bg-[#c6c6ca] active:bg-[#c4c4c7]":
            true,
        "text-[14px] py-0_5 px-1 leading-none": size === "sm",
        "test-[16px] py-0_75 px-1_5 leading-5": size === "md",
    });

    return (
        <span className={styles} title={title}>
            {value}
        </span>
    );
};

export default Tag;
