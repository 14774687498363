import { useState } from "react";
import { GetHandleProps } from "react-compound-slider/dist/types/Handles/types";

type MediaPlayerSliderHandleProps = {
    domainMin: number;
    domainMax: number;
    sliderHandlePercent: number;
    sliderHandleId: string;
    sliderHandleValue: number;
    isActive: boolean;
    getHandleProps: GetHandleProps;
    getDisplayValue: Function;
};

const MediaPlayerSliderHandle = ({
    domainMin,
    domainMax,
    sliderHandlePercent,
    sliderHandleId,
    sliderHandleValue,
    isActive,
    getDisplayValue,
    getHandleProps,
}: MediaPlayerSliderHandleProps) => {
    const [mouseOver, setMouseOver] = useState(false);

    const onMouseEnter = () => {
        setMouseOver(true);
    };

    const onMouseLeave = () => {
        setMouseOver(false);
    };

    return (
        <div id="media-player-slider-handle" data-testid="media-player-slider-handle">
            {getDisplayValue && (mouseOver || isActive) ? (
                <div
                    id="media-player-slider-handle-tooltip"
                    data-testid="media-player-slider-handle-tooltip"
                    className="absolute translate-x-[-50%] top-[-25px] z-10 ml-[6px] py-0 px-[5px] text-status-information-bold-text bg-gray-bold-active bg-opacity-80"
                    style={{
                        left: `${sliderHandlePercent}%`,
                    }}>
                    {getDisplayValue(sliderHandleValue)}
                </div>
            ) : null}
            <div
                id="media-player-slider-interactive-handle"
                data-testid="media-player-slider-interactive-handle"
                className="absolute z-[400] w-0_25 h-0_5 cursor-pointer bg-none"
                style={{
                    left: `${sliderHandlePercent}%`,
                }}
                {...getHandleProps(sliderHandleId, {
                    onMouseEnter,
                    onMouseLeave,
                })}
            />
            <div
                id="media-player-slider-display-handle"
                className="absolute z-[300] w-0_25 h-0_5 bg-gray-subtle-active border-none"
                style={{
                    left: `${sliderHandlePercent}%`,
                }}
                role="slider"
                aria-valuemin={domainMin}
                aria-valuemax={domainMax}
                aria-valuenow={sliderHandleValue}
                aria-label="media-player-slider-display-handle"
            />
        </div>
    );
};

export default MediaPlayerSliderHandle;
