import { Interest, FollowHooks } from "../types.ts";
import { useCustomInterestFollow } from "./useCustomInterestFollow.ts";
import { useNoTagFollow } from "./useNoTagFollow.ts";
import { useRecommendFollow } from "./useRecommendFollow.ts";

export const useFollow = (followItem: Interest, withDropdown: boolean): FollowHooks => {
    // This hook is used to determine which follow hook to use based on the followItem type

    // If the followItem has a customInterest property, use the useCustomInterestFollow hook
    const customInterestFollow = useCustomInterestFollow(followItem, withDropdown, !!followItem.customInterest);

    // If the followItem has a tag property and is not a customInterest, use the useRecommendFollow hook
    const recommendedFollow = useRecommendFollow(
        followItem,
        withDropdown,
        !!followItem.tag && !followItem.customInterest
    );

    // If the followItem does not have a tag, use the useNoTagFollow hook
    const noTagFollow = useNoTagFollow(followItem, withDropdown, !followItem.tag && !followItem.customInterest);

    return {
        ...((customInterestFollow ?? recommendedFollow ?? noTagFollow) as FollowHooks),
    };
};
