"use client";

import React, { useState } from "react";
import { ForgeIcon } from "@forge-ui-components/atoms";
import { labelViewAllFinancials } from "./Constants.ts";
import CompanyFinancials from "./CompanyFinancials.tsx";
import { ExhibitReference } from "./types.ts";

type CollapsibleCompanyFinancialsProps = {
    exhibitReferencesObject: Record<string, ExhibitReference>;
    source?: string;
};

// List of names of all the financials sections that we show in the Collapsible Financials
const financialSectionNamesList = [
    "ratiosAndValuationsExhibit",
    "growthMarginsExhibit",
    "assetQualityExhibit",
    "capitalItemsExhibit",
    "profitModelExhibit",
    "balanceSheetExhibit",
    "cashFlowExhibit",
    "profitDriversExhibit",
    "profitabilityExhibit",
];

/**
 * We only show the Collapsible Financials if certain types of exhibit data are present.
 * @param exhibitReferences - Object containing all Exhibit Referecnes
 * @returns
 */
const summaryFinancialsAvailable = (exhibitReferences: Record<string, ExhibitReference>): boolean =>
    financialSectionNamesList.some((type) => Object.prototype.hasOwnProperty.call(exhibitReferences, type));

const CollapsibleCompanyFinancials: React.FC<CollapsibleCompanyFinancialsProps> = (props) => {
    const { exhibitReferencesObject, source } = props;
    const [showFinancials, setShowFinancials] = useState(false);

    if (summaryFinancialsAvailable(exhibitReferencesObject)) {
        return (
            <div>
                <div className="mb-[25px]">
                    <button
                        data-testid="view-all-financials-button"
                        onClick={() => setShowFinancials(!showFinancials)}
                        role="tab"
                        aria-expanded={showFinancials}
                        aria-controls="collapseData"
                        className="flex items-center mx-auto my-0 text-scheme-primary border-0 bg-surface-primary text-[18px] px-[38px] py-[13px]"
                        type="button">
                        {labelViewAllFinancials}
                        {showFinancials ? (
                            <ForgeIcon icon="double-arrow-up" pxSize={20} color="#186ade" classNames="ml-[10px]" />
                        ) : (
                            <ForgeIcon icon="double-arrow-down" pxSize={20} color="#186ade" classNames="ml-[10px]" />
                        )}
                    </button>
                </div>
                {showFinancials && (
                    <>
                        <CompanyFinancials exhibitReferencesObject={exhibitReferencesObject} />
                        {source && <div className="pt-1_5 font-sansCondensed text-xs leading-tight">{source}</div>}
                    </>
                )}
            </div>
        );
    }

    return null;
};

export default CollapsibleCompanyFinancials;
