"use client";

import { useState } from "react";
import { ForgeIcon } from "@forge-ui-components/atoms";
import { NavigationBarItem } from "@forge-cms/models/components";
import { HeaderProps } from "./types.ts";

type NavBarMenuProps = {
    items: NavigationBarItem[];
    onExpandSubMenu: (index: number) => void;
};

type SubNavBarMenuProps = {
    heading?: string;
    subItems?: NavigationBarItem[];
    onHideSubMenu: () => void;
};

type NavBarItemProps = {
    item: NavigationBarItem;
    onExpandSubMenu?: () => void;
    isSubNavBarItemHeading?: boolean;
};

const NavBarItem = ({ item, onExpandSubMenu, isSubNavBarItemHeading }: NavBarItemProps) => (
    <li className="hover:bg-white border-b border-[#e3e3e3] flex flex-row justify-end">
        <a
            href={item.link}
            className={`w-[100%] uppercase py-[12px] pl-[20px] inline-block !no-underline ${
                isSubNavBarItemHeading ? "font-bold" : "font-normal"
            } font-sans text-[14px] text-[#434343] visited:text-[#434343]`}>
            {item.name}
        </a>
        {onExpandSubMenu && item.items?.length > 0 && (
            <button
                type="button"
                className="w-[50px] bg-white border-l border-[#e3e3e3]"
                onClick={onExpandSubMenu}
                aria-label="Expand Menu">
                <ForgeIcon icon="arrow-forward-ios" pxSize={12} classNames="mx-auto" />
            </button>
        )}
    </li>
);

const NavBarMenu = ({ items, onExpandSubMenu }: NavBarMenuProps) => (
    <div className={`bg-[#e3e3e3] w-full flex-[0_0_100%] `}>
        <div className="pt-[50px]">
            <ul className="flex flex-col flex-wrap bg-[#f5f5f5]">
                {items.map((item, i) => (
                    <NavBarItem item={item} onExpandSubMenu={() => onExpandSubMenu(i)} />
                ))}
            </ul>
        </div>
    </div>
);

const SubNavBarMenu = ({ heading, subItems, onHideSubMenu }: SubNavBarMenuProps) => (
    <div
        className={`z-10 flex-[0_0_100%] bg-[#e3e3e3] ${
            subItems ? "transition-all duration-500 -translate-x-full" : "transition-all duration-500 translate-x-full"
        }`}>
        <div className="pt-[50px] flex flex-row h-full">
            <button
                type="button"
                className="w-[20px] text-white text-[10px] h-full flex flex-col justify-center"
                onClick={onHideSubMenu}
                aria-label="Hide Menu">
                <span className="ml-[5px]">
                    <ForgeIcon icon="arrow-back-ios" pxSize={12} />
                </span>
            </button>
            <ul className="flex flex-col flex-wrap bg-[#f5f5f5] w-full pl-[10px]">
                {heading && <NavBarItem item={{ name: heading, items: [] }} isSubNavBarItemHeading />}
                {subItems && subItems.map((subItem) => <NavBarItem item={subItem} />)}
            </ul>
        </div>
    </div>
);

const MobileHeader = ({
    navItems,
    myContentNavItems,
    myContentLink,
    logo,
    searchBar,
    userProfile,
    className,
}: HeaderProps) => {
    const items = [...navItems];
    if (myContentLink || (myContentNavItems && myContentNavItems?.length > 0)) {
        items.unshift({
            name: "My Content",
            link: myContentLink,
            items: myContentNavItems,
        });
    }

    const [showSearchBar, setShowSearchBar] = useState(false);
    const [showNavBarMenu, setShowNavBarMenu] = useState(false);
    const [selectedNavBarIndex, setSelectedNavBarIndex] = useState(-1);

    return (
        <div className={`bg-white top-0 w-full z-10 pt-2 border-b border-[#e4e4e4] ${className || ""}`}>
            <div className="my-0 mx-auto min-h-10">
                <div className="flex flex-row pl-2 pb-[20px]">
                    <button type="button" onClick={() => setShowNavBarMenu(!showNavBarMenu)} aria-label="Toggle Navbar">
                        <span className="my-auto">
                            {showNavBarMenu ? (
                                <ForgeIcon icon="close" pxSize={45} />
                            ) : (
                                <ForgeIcon icon="menu" pxSize={45} />
                            )}
                        </span>
                    </button>
                    {logo}
                    <div className="flex flex-grow flex-row gap-x-1 justify-end space-x-1 content-center text-center">
                        {userProfile}
                        <button
                            type="button"
                            onClick={() => setShowSearchBar(!showSearchBar)}
                            aria-label="Toggle search bar">
                            <span className="mr-[10px] block">
                                {showSearchBar ? (
                                    <ForgeIcon icon="close" pxSize={30} color="#186ade" />
                                ) : (
                                    <ForgeIcon icon="search" pxSize={30} color="#186ade" />
                                )}
                            </span>
                        </button>
                    </div>
                </div>
                {showSearchBar && <div className="py-1">{searchBar}</div>}
                {showNavBarMenu && (
                    <div className="flex flex-row overflow-hidden">
                        <NavBarMenu items={items} onExpandSubMenu={(index: number) => setSelectedNavBarIndex(index)} />
                        <SubNavBarMenu
                            heading={selectedNavBarIndex >= 0 ? items[selectedNavBarIndex].name : undefined}
                            subItems={selectedNavBarIndex >= 0 ? items[selectedNavBarIndex].items : undefined}
                            onHideSubMenu={() => setSelectedNavBarIndex(-1)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default MobileHeader;
