"use client";

import MediaPlayer from "@forge-ui-components/media-player";

type MediaProps = {
    media: {
        headline: string;
        url: string;
    };
};

const transformMedia = (media: MediaProps) => ({
    headline: media?.media?.headline,
    url: media?.media?.url,
});

const MediaEvent = (media: any) => {
    const transformMediaData = transformMedia(media);
    const index = transformMediaData?.headline?.indexOf(":");
    return (
        index > -1 && (
            <MediaPlayer
                mediaType={transformMediaData.headline.substring(0, index)}
                mediaUrl={transformMediaData.headline.substring(index + 1)}
                mediaThumbnailUrl={transformMediaData.url}
            />
        )
    );
};

export default MediaEvent;
