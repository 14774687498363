import classNames from "classnames";
import { Interest } from "../types.ts";

export const buttonClasses = (interest: Interest) =>
    classNames({
        "w-[120px] mr-[8px] border !border-blue-bold rounded [&>button]:items-center [&>button]:py-[4px] [&>button]:px-[15px] [&>button]:uppercase [&>button]:text-[12px] [&>button]:rounded [&>button>span]:!p-0":
            true,
        "[&>button]:bg-surface-primary [&>button]:text-text-link [&>button]:hover:bg-blue-bold [&>button]:hover:text-text-reversed":
            !interest.followActive,
        "[&>button]:flex [&>button]:bg-blue-bold [&>button]:text-text-reversed": interest.followActive,
    });

export const errorAlert = classNames({
    "text-sm text-red-500 p-1": true,
});
