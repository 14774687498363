import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import { RailObject } from "react-compound-slider/dist/types/Rail/types";
import MediaPlayerSliderTracker from "./SliderTracker.tsx";
import MediaPlayerRailDisplay from "./SliderRail.tsx";
import MediaPlayerSliderHandle from "./SliderHandle.tsx";

const sliderStyle = {
    position: "relative",
    width: "100%",
    height: `100%`,
};

export type MediaPlayerSliderProps = {
    sliderTimeValue: number;
    sliderMinValue: number;
    sliderMaxValue: number;
    sliderStepSize: number;
    onSlideStart?: Function;
    onSlideEnd?: Function;
    onChange?: Function;
    getDisplayValue: Function;
};

const MediaPlayerSlider = ({
    sliderTimeValue,
    sliderMinValue,
    sliderMaxValue,
    sliderStepSize,
    onSlideStart,
    onSlideEnd,
    onChange,
    getDisplayValue,
}: MediaPlayerSliderProps) => (
    <div
        id="media-player-slider"
        data-testid="media-player-slider"
        className="h-0_5 bg-surface-contrast-active bg-opacity-30">
        <Slider
            mode={1}
            step={sliderStepSize}
            domain={[sliderMinValue, sliderMaxValue]}
            rootStyle={sliderStyle}
            values={[sliderTimeValue]}
            onSlideStart={() => onSlideStart}
            onSlideEnd={([newValue]) => onSlideEnd && onSlideEnd(newValue)}
            onChange={([newValue]) => onChange && onChange(newValue)}>
            <Rail>
                {({ activeHandleID, getEventData, getRailProps }: RailObject) => (
                    <MediaPlayerRailDisplay
                        activeHandleID={activeHandleID}
                        getEventData={getEventData}
                        getRailProps={getRailProps}
                        getDisplayValue={getDisplayValue}
                        onSlideStart={onSlideStart}
                    />
                )}
            </Rail>
            <Handles>
                {({ handles, activeHandleID, getHandleProps }) => (
                    <div>
                        {handles.map((handle) => (
                            <MediaPlayerSliderHandle
                                key={handle.id}
                                sliderHandleId={handle.id}
                                sliderHandlePercent={handle.percent}
                                sliderHandleValue={handle.value}
                                domainMin={sliderMinValue}
                                domainMax={sliderMaxValue}
                                isActive={handle.id === activeHandleID}
                                getDisplayValue={getDisplayValue}
                                getHandleProps={getHandleProps}
                            />
                        ))}
                    </div>
                )}
            </Handles>
            <Tracks right={false}>
                {({ tracks, getTrackProps }) => (
                    <div>
                        {tracks.map(({ id, source, target }) => (
                            <MediaPlayerSliderTracker
                                key={id}
                                sourcePercentage={source.percent}
                                targetPercentage={target.percent}
                                getTrackProps={getTrackProps}
                            />
                        ))}
                    </div>
                )}
            </Tracks>
        </Slider>
    </div>
);

export default MediaPlayerSlider;
