"use client";

import type { Event } from "@forge-cms/models/components";
import { ForgeIcon } from "@forge-ui-components/atoms";
import { Button } from "@gs-ux-uitoolkit-react/button";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import ReactHtmlParser from "react-html-parser";
import { DateUtils } from "../utils/dateUtils.ts";
import { doesElementExist, eventPropertyMap } from "../utils/utils.ts";
import { Events, hostSpeakerInfo } from "../EventType.ts";
import DateTime from "./DateTime.tsx";
import DialInDetails from "./DialInDetails.tsx";
import EventDescription from "./EventDescription.tsx";
import MediaEvent from "./MediaEvent.tsx";
import Passcode from "./Passcode.tsx";
import Speakers from "./Speakers.tsx";

dayjs.extend(utc);
dayjs.extend(timezone);

type EventProps = {
    key?: number;
    event: Events;
    order: string;
};

const Event = ({ event, key, order }: EventProps) => {
    const isCalendarAvailable = DateUtils.getComparisonDate(dayjs(event?.endDate), dayjs()) === "(+1)";

    const renderSection = (renderEvent: Events, element: string) => {
        switch (element) {
            case "title":
                return (
                    <div>
                        <a className="text-text-primary hover:underline hover:text-blue-bold" href={event.titleUrl}>
                            <h2 className="text-base font-bold leading-5 mb-0_25">{renderEvent.title}</h2>
                        </a>
                    </div>
                );
            case "hostdivision":
                return (
                    <div>
                        <h3 className="text-sm text-gray-400 font-bold">{renderEvent?.hostDivision}</h3>
                    </div>
                );
            case "date":
                return (
                    <div>
                        <h3 className="text-sm text-[#666666] font-bold mb-0_25 pt-[3px]">
                            <DateTime event={renderEvent} />
                        </h3>
                    </div>
                );
            case "calendar":
                return (
                    isCalendarAvailable && (
                        <div className="flex pb-[10px] mb-[10px]">
                            <ForgeIcon icon="calendar" pxSize={20} color="#186ADE" /> &nbsp;
                            <a href="#" className="text-blue-bold font-bold hover:underline">
                                Add to Calendar
                            </a>
                            <br />
                        </div>
                    )
                );
            case "description":
                return <EventDescription description={renderEvent.desc} />;
            case "mediaupload":
                return (
                    <div className="block">
                        <MediaEvent media={renderEvent.media[0]} />
                    </div>
                );
            case "region":
                return (
                    <div className="uppercase text-sm text-gray-400 font-bold mb-0_25">
                        {renderEvent.type}: {renderEvent.region}
                    </div>
                );
            case "agenda":
                return doesElementExist(renderEvent, eventPropertyMap.agenda) ? (
                    <div>
                        <a href={renderEvent.agendaUrl?.url} target="_blank" rel="noopener noreferrer">
                            {renderEvent.agendaUrl.description}
                        </a>
                    </div>
                ) : null;
            case "registration":
                return doesElementExist(renderEvent, eventPropertyMap.registration) ? (
                    <div>
                        <a href={renderEvent.regUrl.url} target="_blank" rel="noopener noreferrer">
                            <p>{renderEvent.regUrl?.description?.toUpperCase()}</p>
                        </a>
                    </div>
                ) : null;
            case "webcast":
                return doesElementExist(renderEvent, eventPropertyMap.webcast) ? (
                    <div>
                        <Button
                            onClick={() => window.open(renderEvent.webcastUrl.url, "_blank")}
                            actionType="primary"
                            emphasis="bold"
                            style={{ borderRadius: "8px", marginBottom: "10px", marginTop: "3px" }}>
                            {renderEvent.webcastUrl?.description?.toUpperCase()}
                        </Button>
                    </div>
                ) : null;

            case "weburl":
                return doesElementExist(renderEvent, eventPropertyMap.weburl) ? (
                    <div>
                        <a href={renderEvent.webUrl.url} target="_blank" rel="noopener noreferrer">
                            {renderEvent.webUrl.description}
                        </a>
                    </div>
                ) : null;

            case "webcastpassword":
                return doesElementExist(renderEvent, eventPropertyMap.password) ? (
                    <div className="pb-[15px]">
                        <span className="font-medium">Passcode: </span>
                        <span className="font-300">{renderEvent.password}</span>
                    </div>
                ) : null;

            case "curatedpage":
                return doesElementExist(renderEvent, eventPropertyMap.curatedpage) ? (
                    <div className="pb-[5px]">
                        <a href={renderEvent.curatedPageUrl.url} target="_blank" rel="noopener noreferrer">
                            {renderEvent.curatedPageUrl.description}
                        </a>
                    </div>
                ) : null;

            case "relatedresearch":
                return doesElementExist(renderEvent, eventPropertyMap.relatedresearch)
                    ? renderEvent.research.map((research: any) => (
                          <div key={research.url}>
                              <a
                                  className="text-blue-bold hover:underline"
                                  href={research.url}
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  <p>{research.headline}</p>
                              </a>
                          </div>
                      ))
                    : null;
            case "moderators":
                return doesElementExist(renderEvent, eventPropertyMap.hosts) ? (
                    <div>{renderEvent.hosts.map((curr: hostSpeakerInfo) => Speakers(curr))}</div>
                ) : null;

            case "speakers":
                return doesElementExist(renderEvent, eventPropertyMap.speakers) ? (
                    <div>{renderEvent.speakers.map((curr: hostSpeakerInfo) => Speakers(curr))}</div>
                ) : null;

            case "dialdetails":
                return doesElementExist(renderEvent, eventPropertyMap.dialDetails) ? (
                    <div className="pt-[15px] pb-[3px]">
                        {" "}
                        <DialInDetails phoneNumber={event?.dialIn?.phoneNumbers} />
                    </div>
                ) : null;
            case "hostsText":
                return doesElementExist(renderEvent, eventPropertyMap.hostsText)
                    ? ReactHtmlParser(renderEvent.hostsText ?? "")
                    : null;

            case "speakersText":
                return doesElementExist(renderEvent, eventPropertyMap.speakersText) ? (
                    <div>{ReactHtmlParser(renderEvent.hostsText ?? "")}</div>
                ) : null;

            case "dialpasscode":
                return doesElementExist(renderEvent, eventPropertyMap.dialIn) ? (
                    <div className="pb-[15px]">
                        <Passcode code={renderEvent.dialIn.passcode} />
                    </div>
                ) : null;
            case "researchText":
                return doesElementExist(renderEvent, eventPropertyMap.researchText)
                    ? ReactHtmlParser(renderEvent.hostsText ?? "")
                    : null;
            default:
                return null;
        }
    };

    const sectionContent = renderSection(event, order);
    return sectionContent ? <div key={key}>{sectionContent}</div> : null;
};

export default Event;
