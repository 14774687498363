import { TAG_DOMAIN_MAP, API_URL } from "./constants.ts";
import { Interest, Tag } from "./types.ts";

export const getSaveInterestURL = (hasTag: boolean, hasFrequency: boolean) => {
    if (hasTag && hasFrequency) {
        return API_URL.RECOMMENDED_INTERESTS;
    }
    if (hasTag && !hasFrequency) {
        return API_URL.INTERESTS;
    }
    return API_URL.QUERY;
};

function includes(arr: string[], tagDomain: string) {
    return arr.includes(tagDomain);
}

export const mapTagDomain = (tagDomain: keyof typeof TAG_DOMAIN_MAP) => {
    if (tagDomain in TAG_DOMAIN_MAP) {
        return TAG_DOMAIN_MAP[tagDomain];
    }
    if (includes(Object.values(TAG_DOMAIN_MAP), tagDomain)) {
        return tagDomain;
    }

    return "INACTIVE";
};

export const removeHyphens = (hyphenated: string) => hyphenated?.replace(/-/g, "");

export const formatForRequest = (tag: Tag) => ({
    ...tag,
    tagDomain: mapTagDomain(tag?.tagDomain as any),
    tagGuid: removeHyphens(tag?.tagGuid as string),
});

export const transformTag = (itemToFollow: Interest) => {
    const itemsToFollow = { ...itemToFollow };
    if (itemsToFollow.source) delete itemsToFollow.source;

    if (itemsToFollow.interestId) {
        itemsToFollow.interestId = removeHyphens(itemsToFollow.interestId);
    }

    return {
        ...itemsToFollow,
        tag: itemsToFollow.tag ? formatForRequest(itemsToFollow.tag) : undefined,
    };
};

export function isAlertingSuspended() {
    if (typeof window !== "undefined") {
        const alertConfiguration = window?.localStorage.getItem("alertConfiguration");
        if (alertConfiguration) {
            const alertConfig = JSON.parse(alertConfiguration);
            return !!alertConfig.suspended;
        }
    }
    return false;
}

export const isEmpty = (array: any[]) => array.length === 0;
