import React, { PropsWithChildren } from "react";
import { TypographyColor } from "@gs-ux-uitoolkit-common/design-system";

type Extends<T, U extends T> = U;
type SupportedTextTypographies = "body01" | "body02" | "body03" | "body04" | "body05" | "report01";
type SupportedColors = Extends<TypographyColor, "primary" | "secondary">;

const typographyToTailwindClassMap: Record<SupportedTextTypographies, string> = {
    body01: "font-sans font-normal text-base",
    body02: "font-sans font-normal text-sm",
    body03: "font-sans font-normal text-xs",
    body04: "font-sans font-normal text-[11px]/[16px]",
    body05: "font-sans font-normal text-[10px]/[12px]",
    report01: "font-sans font-light text-[0.875em] mb-2_5",
};

const colorToTailwindClassMap: Record<SupportedColors, string> = {
    primary: "text-text-primary",
    secondary: "text-text-secondary",
};

type TextProps = PropsWithChildren<{
    typography?: keyof typeof typographyToTailwindClassMap;
    color?: keyof typeof colorToTailwindClassMap;
    className?: string;
    "data-testid"?: string;
}>;

const Text: React.FC<TextProps> = ({
    typography = "body02",
    color = "primary",
    className,
    children,
    ...props
}: TextProps) => {
    const typographyClasses = typographyToTailwindClassMap[typography];
    const colorClasses = colorToTailwindClassMap[color];

    return (
        <div className={`${typographyClasses} ${colorClasses} ${className}`} data-testid={props["data-testid"]}>
            {children}
        </div>
    );
};

export type { TextProps, SupportedTextTypographies };
export default Text;
