import classNames from "classnames";

export const followItemClasses = classNames({
    "flex items-center justify-between py-[10px] border-t border-solid border-[#EEEEEE]": true,
});

export const modalFooterClasses = classNames({
    "justify-between": true,
});

export const modalSectionClasses = classNames({
    "px-[20px]": true,
});
