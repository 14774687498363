import { useState, useEffect, useMemo } from "react";
import { Interest, FollowHooks } from "../types.ts";
import { isEmpty, transformTag } from "../utils.ts";
import { putInterest, removeInterestData } from "../requestHandler.ts";

import { API_URL } from "../constants.ts";

export const useNoTagFollow = (
    followItem: Interest,
    withDropdown: boolean,
    shouldExecute: boolean
): FollowHooks | null => {
    const [interest, setInterest] = useState<Interest>({
        followActive: false,
        alertActive: false,
        distributionChannels: [],
        includeAlerts: [],
    });

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [isFollowLoading, setIsFollowLoading] = useState(false);
    const [showBellIcon] = useState(false);
    const [isError, setIsError] = useState(false);
    const handleErrors = (error: boolean) => {
        setIsError(error);
    };

    const saveInterestData = (interests: Interest[] | Interest) => putInterest(API_URL.QUERY, interests, handleErrors);

    const createInterest = async () => {
        const payload = {
            advancedSearchQuery: followItem?.advancedSearchQuery,
            frequency: followItem?.frequency ?? "As published",
            distributionChannels: ["email"],
            includeAlerts: ["event"],
            interestName: followItem?.interestName,
            alertActive: true,
        };

        const response = await saveInterestData(payload);
        setInterest({ ...response });
        setIsFollowLoading(false);
    };

    const deleteQueryInterest = (interestObj: Interest) =>
        removeInterestData(interestObj?.interestId, handleErrors).then(() => {
            setInterest({
                ...interestObj,
                followActive: false,
                alertActive: false,
                distributionChannels: [],
                includeAlerts: [],
            });
        });

    const deleteInterest = async () => {
        deleteQueryInterest(interest);
    };

    const handleFollowButtonClick = async () => {
        // If the interest is not followed, create the interest
        if (!interest?.followActive) {
            setIsFollowLoading(true);
            await createInterest();
        } else if (!withDropdown) {
            // If the interest is followed and the dropdown is not allowed, delete the interest
            setIsFollowLoading(true);
            await deleteInterest();
        }
        // If the interest is followed and the dropdown is allowed, toggle the dropdown visibility
        if (withDropdown && !isFollowLoading) {
            setDropdownVisible(!dropdownVisible);
        }
    };

    // ------------------------------------------- Methods to update checkboxes --------------------------------------------------

    const updateIncludeAlerts = async (includeAlerts: string[]) => {
        const updatedInterest = {
            interestId: interest.interestId,
            advancedSearchQuery: followItem.advancedSearchQuery,
            interestName: interest.interestName,
            frequency: interest.frequency,
            distributionChannels: interest.distributionChannels,
            includeAlerts,
            alertActive: interest.alertActive,
        };
        const response = await saveInterestData(updatedInterest);
        setInterest({ ...response });
    };

    const updateDistributionFrequency = async (frequency: string) => {
        const updatedInterest = {
            interestId: interest.interestId,
            advancedSearchQuery: followItem?.advancedSearchQuery,
            interestName: interest.interestName,
            frequency,
            distributionChannels: interest.distributionChannels,
            includeAlerts: interest.includeAlerts,
            alertActive: interest.alertActive,
        };
        const response = await saveInterestData(updatedInterest);
        setInterest({ ...response });
    };

    const toggleEmailAlertDistributions = async () => {
        let distributionChannelsList = interest?.distributionChannels || [];
        let includeAlerts = interest?.includeAlerts || [];
        const enableAlert = !distributionChannelsList.includes("email");
        if (enableAlert) {
            distributionChannelsList.push("email");
            if (!includeAlerts.includes("event")) {
                includeAlerts.push("event");
            }
        } else {
            distributionChannelsList = distributionChannelsList.filter((c: string) => c !== "email");
            includeAlerts = includeAlerts.filter((v) => v !== "event");
        }

        const alertActive = !isEmpty(distributionChannelsList);
        const interestObj = {
            interestId: interest.interestId,
            advancedSearchQuery: followItem.advancedSearchQuery,
            frequency: interest.frequency,
            interestName: interest.interestName,
            alertActive,
            distributionChannels: distributionChannelsList,
            includeAlerts,
        };

        const response = await saveInterestData(interestObj);
        setInterest({ ...response });
    };

    const toggleMobileAlertDistributions = async () => {
        let distributionChannelsList = interest?.distributionChannels || [];
        const enableAlert = !distributionChannelsList.includes("mobile");

        if (enableAlert) {
            distributionChannelsList.push("mobile");
        } else {
            distributionChannelsList = distributionChannelsList.filter((c: string) => c !== "mobile");
        }

        const alertActive = !isEmpty(distributionChannelsList);
        const interestObj = {
            interestId: interest.interestId,
            advancedSearchQuery: followItem.advancedSearchQuery,
            frequency: interest.frequency,
            interestName: interest.interestName,
            alertActive,
            distributionChannels: distributionChannelsList,
            includeAlerts: interest.includeAlerts,
        };
        const response = await saveInterestData(interestObj);
        setInterest({ ...response });
    };

    const toggleIncludeAlerts = () => {
        let includeAlerts = interest?.includeAlerts || [];
        if (includeAlerts.includes("event")) {
            includeAlerts = includeAlerts.filter((v) => v !== "event");
        } else {
            includeAlerts.push("event");
        }
        updateIncludeAlerts(includeAlerts);
    };

    const toggleAlerts = (type: string) => {
        switch (type) {
            case "email":
                toggleEmailAlertDistributions();
                break;
            case "mobile":
                toggleMobileAlertDistributions();
                break;
            case "event":
                toggleIncludeAlerts();
                break;
            default:
                break;
        }
    };
    //---------------------------------------------------------------------------------------------

    const handleUnFollowButtonClick = async () => {
        await deleteInterest();
        setDropdownVisible(false);
    };

    const hideMenu = () => {
        setDropdownVisible(false);
    };

    const distributionChannels = useMemo(() => interest?.distributionChannels || [], [interest]);

    useEffect(() => {
        const getInterestDetails = async () => {
            if (followItem) {
                const transformedFollowItem = transformTag(followItem);
                setInterest(transformedFollowItem);
            }
        };
        getInterestDetails();
    }, [followItem]);

    if (!shouldExecute) return null;

    return {
        isError,
        interest,
        isFollowLoading,
        showBellIcon,
        dropdownVisible,
        setDropdownVisible,
        hideMenu,
        distributionChannels,
        onFrequencyChange: updateDistributionFrequency,
        handleFollow: handleFollowButtonClick,
        handleUnFollow: handleUnFollowButtonClick,
        toggleAlerts,
    };
};
