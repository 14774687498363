type SpeakersProps = {
    name: string;
    title: string;
};
const Speakers = ({ name, title }: SpeakersProps) => (
    <div>
        {name && title ? (
            <div className="pb-px">
                <span className="font-bold text-sm leading-5">{name}</span>
                <span className="text-sm font-normal leading-5">, {title}</span>
            </div>
        ) : null}
    </div>
);

export default Speakers;
