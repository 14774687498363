"use client";

import { ForgeIcon } from "@forge-ui-components/atoms";

export type DownloadEmailSummaryProps = {
    label?: string;
    distributionHeadline: string;
};

const DownloadEmailSummary: React.FC<DownloadEmailSummaryProps> = ({
    distributionHeadline,
    label = "Download email summary",
}: DownloadEmailSummaryProps) => {
    const downloadEmailSummary = async (event: React.MouseEvent<HTMLButtonElement>) => {
        try {
            event.preventDefault();
            const lastDotIndex = window.location.href.lastIndexOf(".");
            const slicedUrl = window.location.href.slice(0, lastDotIndex - window.location.href.length);

            const response = await fetch(`${slicedUrl}.hideUnsubscribe.mime`);
            const mime = await response.text();

            const emailContent = `Subject: ${distributionHeadline}\nX-Unsent: 1\n${mime}`;

            const blob = new Blob([emailContent], {
                type: "text/plain",
            });

            const fileName = `${window.document.title}.eml`;

            const url = window.URL.createObjectURL(blob);
            const hyperLink = document.createElement("a");
            hyperLink.style.display = "none";
            hyperLink.href = url;
            hyperLink.download = fileName;
            document.body.appendChild(hyperLink);
            hyperLink.click();
            window.URL.revokeObjectURL(url);
        } catch (err) {
            console.error("Failed to download .mime content", err);
        }
    };

    return (
        <button
            className="flex items-center w-full px-3 py-1 hover:bg-gray-subtle"
            onClick={downloadEmailSummary}
            type="button">
            <div className="justify-self-start">
                <ForgeIcon icon="email" pxSize={20} color="#000000" />
            </div>
            <div className="flex-grow text-left pl-1">{label}</div>
        </button>
    );
};

export default DownloadEmailSummary;
