import Event from "./modules/Event.tsx";

type EventPanelListInterface = {
    reports: any;
    formOrder?: (
        | "title"
        | "description"
        | "date"
        | "hostdivision"
        | "region"
        | "registration"
        | "agenda"
        | "webcast"
        | "weburl"
        | "webcastpassword"
        | "curatedpage"
        | "calendar"
        | "venue"
        | "relatedresearch"
        | "mediaupload"
        | "moderators"
        | "speakers"
        | "speakersText"
        | "hostsText"
        | "researchText"
        | "dialdetails"
        | "dialpasscode"
    )[];
};

// Anything needed here?

const getQueryPanelItem = (report: any, formOrder: any) =>
    formOrder?.map((element: any) => <Event event={report} order={element} />);

const EventPanelList = ({ reports, formOrder }: EventPanelListInterface) => (
    <div className="divide-y divide-neutral-200">
        {reports?.map((report: any, tabIndex: number) => (
            <div
                id={`events-query-item-container-${tabIndex}`}
                data-testid="events-query-item-container"
                className="flex text-sm font-normal">
                <div data-testid="events-query-item-content" className="mt-[10px] mb-[15px] pt-[10px] pb-[1px]">
                    {getQueryPanelItem(report, formOrder)}
                </div>
            </div>
        ))}
    </div>
);

export default EventPanelList;
