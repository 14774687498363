import { Link, Text } from "@gs-ux-uitoolkit-react/text";
import { Heading } from "@forge-ui-components/atoms";

type ExhibitGalleryThumbnailProps = {
    /**
     * Total number of exhibits on the page
     */
    exhibitCount: number;
    /**
     * Thumbnail to display in the preview of the gallery
     */
    exhibitThumbnail: string;
    /**
     * Visibility toggle function used to open the gallery
     */
    openGallery: () => void;
    /**
     * Localised title used for the Exhibit Gallery thumbnail
     */
    galleryTitle: string;
    /**
     * Localised label for "View All"
     */
    viewAllLabel: string;
};

const ExhibitGalleryThumbnail = ({
    openGallery,
    exhibitCount,
    exhibitThumbnail,
    galleryTitle,
    viewAllLabel = "View all",
}: ExhibitGalleryThumbnailProps) => (
    <section id="exhibit-gallery-thumbnail">
        <Heading typography="heading04" className="mb-2">
            {galleryTitle}
        </Heading>
        <Link className="mb-2" onClick={openGallery}>
            <img src={exhibitThumbnail} alt="Gallery" />
        </Link>
        <Link onClick={openGallery}>
            <Text>
                {exhibitCount > 1 ? (
                    <>
                        {viewAllLabel} <b>{exhibitCount}</b> exhibits
                    </>
                ) : (
                    <>View exhibit</>
                )}
            </Text>
        </Link>
    </section>
);

export type { ExhibitGalleryThumbnailProps };
export default ExhibitGalleryThumbnail;
