import { Text } from "@gs-ux-uitoolkit-react/text";
import moment from "moment-timezone";

export type SearchItemMetadataProps = {
    totalPages?: number;
    leadAuthor: string;
    hasMultipleAuthors: boolean;
    sourceDisplayName?: string;
    publicationDateTime: number;
};

const monthDisplay = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const getPublicationDateTime = (time: number) => {
    const momentDateTime = moment(time);
    const dateTime = momentDateTime.toDate();
    const minutes = dateTime.getMinutes();
    const formattedTime = `${dateTime.getHours() % 12}:${minutes < 10 ? `0${minutes}` : minutes}${
        dateTime.getHours() > 12 ? "pm" : "am"
    }`;
    const formattedDate = `${dateTime.getDate()} ${monthDisplay[dateTime.getMonth()]} ${dateTime.getUTCFullYear()}`;

    return time ? `${formattedDate} | ${formattedTime} |` : "";
};

const getPageCount = (pageCount?: number) => (pageCount ? `${pageCount}pg |` : "");

const getDisplaySourceName = (source?: string) => (source ? `${source}` : "");

const getAuthorDisplay = (leadAuthor: string, hasMultipleAuthors: boolean) =>
    `${leadAuthor}${hasMultipleAuthors ? " and others" : ""}`;

const SearchItemMetadata = ({
    totalPages,
    leadAuthor,
    hasMultipleAuthors,
    sourceDisplayName,
    publicationDateTime,
}: SearchItemMetadataProps) => (
    <div data-testid="search-item-metadata">
        <Text color="tertiary">
            {`${getPublicationDateTime(publicationDateTime)} ${getPageCount(totalPages)} ${getDisplaySourceName(
                sourceDisplayName
            )} ${sourceDisplayName && leadAuthor ? "-" : ""} ${getAuthorDisplay(leadAuthor, hasMultipleAuthors)}`}
        </Text>
    </div>
);

export default SearchItemMetadata;
