"use client";

import { ReactNode, FC, useState, Children } from "react";
import { ForgeIcon } from "@forge-ui-components/atoms";

type props = {
    children: ReactNode;
};

const Carousel: FC<props> = ({ children: slides }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const previous = () => {
        setCurrentSlide((currentSlideIndex) => {
            const previousSlide = currentSlideIndex === 0 ? Children.count(slides) - 1 : currentSlideIndex - 1;
            return previousSlide;
        });
    };

    const next = () => {
        setCurrentSlide((currentSlideIndex) => {
            const nextSlide = currentSlideIndex === Children.count(slides) - 1 ? 0 : currentSlideIndex + 1;
            return nextSlide;
        });
    };
    return (
        <div className="flex overflow-hidden relative py-2 px-1" data-testid="carousel">
            <button
                className="text-text-link opacity-75 hover:opacity-100"
                onClick={previous}
                aria-label="previous"
                type="button">
                <ForgeIcon icon="chevron-left" pxSize={35} color="#186ade" classNames="inline" />
            </button>
            <div className="overflow-hidden">
                <div
                    data-testid="carousel-slide"
                    className="flex transition-transform ease-out duration-500"
                    style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                    {slides}
                </div>
            </div>
            <button
                className="text-text-link opacity-75 hover:opacity-100"
                onClick={next}
                aria-label="next"
                type="button">
                <ForgeIcon icon="chevron-right" pxSize={35} color="#186ade" classNames="inline" />
            </button>
        </div>
    );
};

export default Carousel;
