import { ForgeIcon } from "@forge-ui-components/atoms";
import { FAST_FORWARD_PROGRESS, HANDLE_TOGGLE_PLAY, REWIND_PROGRESS } from "../actions.ts";

export type PlayPauseButtonProps = {
    isPlaying: boolean;
    dispatch: Function;
    fireMetricsEvent: Function;
};

const PlayPauseButton = ({ isPlaying, dispatch, fireMetricsEvent }: PlayPauseButtonProps) => (
    <button
        id="media-player-play-pause-button"
        data-testid="media-player-play-pause-button"
        type="button"
        onClick={() => dispatch({ type: HANDLE_TOGGLE_PLAY, emitter: fireMetricsEvent })}>
        {isPlaying ? (
            <ForgeIcon
                icon="pause"
                pxSize={17}
                color="#FFFFFF"
                classNames="inline"
                dataTestId="media-player-control-pause-icon"
            />
        ) : (
            <ForgeIcon
                icon="play"
                pxSize={17}
                color="#FFFFFF"
                classNames="inline"
                dataTestId="media-player-control-play-icon"
            />
        )}
    </button>
);

type FastProgressProps = {
    dispatch: Function;
};
const FastForwardButton = ({ dispatch }: FastProgressProps) => {
    const handleChange = () => {
        dispatch({ type: FAST_FORWARD_PROGRESS });
    };

    return (
        <button
            id="media-player-fast-forward-button"
            data-testid="media-player-fast-forward-button"
            className="ml-0_5"
            onClick={handleChange}
            type="button"
            aria-label="media-player-fast-forward">
            <ForgeIcon icon="fast-forward" pxSize={17} color="#FFFFFF" classNames="inline" />
        </button>
    );
};

const FastRewindButton = ({ dispatch }: FastProgressProps) => {
    const handleChange = () => {
        dispatch({ type: REWIND_PROGRESS });
    };

    return (
        <button
            id="media-player-fast-rewind-button"
            data-testid="media-player-fast-rewind-button"
            className="ml-0_5"
            onClick={handleChange}
            type="button"
            aria-label="media-player-fast-rewind">
            <ForgeIcon icon="rewind" pxSize={17} color="#FFFFFF" classNames="inline" />
        </button>
    );
};

export type PlayControlsProps = PlayPauseButtonProps & {
    isSlim?: boolean;
};

/**
 * Media Player Control Buttons to Play, Pause, Fast Forward, and Rewind the media player
 * @param isPlaying         - boolean state to determine if the media player is currently playing
 * @param dispatch          - function to dispatch actions to the media player reducer
 * @param fireMetricsEvent  - function to fire metrics events
 * @constructor
 */
const PlayControls = ({ isPlaying, isSlim, dispatch, fireMetricsEvent }: PlayControlsProps) => (
    <div id="media-player-play-controls" data-testid="media-player-play-controls">
        <PlayPauseButton isPlaying={isPlaying} fireMetricsEvent={fireMetricsEvent} dispatch={dispatch} />
        {isSlim ? <FastRewindButton dispatch={dispatch} /> : null}
        {isSlim ? <FastForwardButton dispatch={dispatch} /> : null}
    </div>
);

export default PlayControls;
