/**
 * Utility function to pad single digit time values with a leading zero
 * @param value - number
 */
function padTimeString(value: number) {
    return `0${value}`.slice(-2);
}

/**
 * Format Utility that converts seconds to HH:MM:SS format
 * @param seconds - number
 */
export const formatSeconds = (seconds: number) => {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = padTimeString(date.getUTCSeconds());
    if (hh) {
        return `${hh}:${padTimeString(mm)}:${ss}`;
    }
    return `${mm}:${ss}`;
};
