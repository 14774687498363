import { ICON_SVG_MAP, Icon } from "./IconSvgMap.ts";

type CommonIconProps = {
    pxSize: number;
    color?: string;
    dataTestId?: string;
    classNames?: string;
};

type IconProps = CommonIconProps & {
    icon: Icon;
};

const ForgeIcon = ({ icon, pxSize, color, classNames, dataTestId }: IconProps) => {
    const svg = ICON_SVG_MAP?.[icon];

    if (!svg) {
        return null;
    }

    return (
        <svg
            data-testid={dataTestId}
            xmlns="http://www.w3.org/2000/svg"
            className={`${classNames || ""}`}
            height={`${pxSize}px`}
            width={`${pxSize}px`}
            fill={`${color || "#000000"}`}
            viewBox="0 -960 960 960">
            <path d={`${svg}`} />
        </svg>
    );
};

export type { Icon, IconProps };
export { ForgeIcon };
