export type LoadingRowsProps = {
    rows?: number;
};

export const LoadingRows = ({ rows = 5 }: LoadingRowsProps) => {
    const loadingRows = Array.from({ length: rows }, (_, i) => (
        <div key={i} className="h-2 bg-gray-200 rounded" data-testid="single-loading-row" />
    ));

    return (
        <div className="space-y-2 animate-pulse" data-testid="loading-container">
            {loadingRows}
        </div>
    );
};

export default LoadingRows;
