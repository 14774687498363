"use client";

import { Heading } from "@gs-ux-uitoolkit-react/text";
import SearchItemMetadata from "./SearchItemMetadata.tsx";

export type SearchItemProps = {
    distributionHeadline: string;
    path: string;
    totalPages: number;
    leadAuthor: string;
    hasMultipleAuthors: boolean;
    sourceDisplayName: string;
    publicationDateTime: number;
    linkTarget?: "_blank" | "_self" | "_parent" | "_top";
    paddingStyleOverrides?: string;
    sendMetrics?: () => void;
};

export const SearchItem = ({
    distributionHeadline,
    path,
    totalPages,
    leadAuthor,
    hasMultipleAuthors,
    sourceDisplayName,
    publicationDateTime,
    linkTarget = "_self",
    paddingStyleOverrides = "px-2_5",
    sendMetrics,
}: SearchItemProps) => (
    <div data-testid="search-item-content" className={paddingStyleOverrides}>
        <Heading typography="heading05" style={{ textTransform: "none" }}>
            <a
                href={path}
                className="text-text-primary hover:underline hover:text-blue-bold"
                target={linkTarget}
                onClick={sendMetrics}>
                {distributionHeadline}
            </a>
        </Heading>
        <SearchItemMetadata
            totalPages={totalPages}
            leadAuthor={leadAuthor}
            hasMultipleAuthors={hasMultipleAuthors}
            sourceDisplayName={sourceDisplayName}
            publicationDateTime={publicationDateTime}
        />
    </div>
);

export default SearchItem;
