import { LoadingIcon } from "@gs-ux-uitoolkit-react/loading";
import classNames from "classnames";
import PlayOverlayIcon, { overlayIconPositionStyles } from "../common/PlayOverlayIcon.tsx";
import { HANDLE_TOGGLE_PLAY } from "../actions.ts";
import { AUDIO_MEDIA_TYPE } from "../constants.ts";

export type PlayOverlayProps = {
    isPlaying: boolean;
    isBuffering: boolean;
    displayPreview: boolean;
    shouldShowOverlay: boolean;
    fireMetricsEvent: Function;
    dispatch: Function;
    mediaType?: string;
};

const PlayOverlay = ({
    isPlaying,
    isBuffering,
    displayPreview,
    shouldShowOverlay,
    fireMetricsEvent,
    dispatch,
    mediaType,
}: PlayOverlayProps) =>
    shouldShowOverlay ? (
        <div
            id="media-player-control-play-overlay"
            data-testid="media-player-control-play-overlay"
            className={classNames({
                "grow bg-black transition ease-in-out delay-150": true,
                "bg-opacity-0": !((isBuffering && isPlaying) || !isPlaying),
                "bg-opacity-30": (isBuffering && isPlaying) || !isPlaying,
            })}
            role="presentation"
            onClick={() => dispatch({ type: HANDLE_TOGGLE_PLAY, emitter: fireMetricsEvent })}>
            {!isPlaying && !displayPreview && mediaType !== AUDIO_MEDIA_TYPE ? (
                <PlayOverlayIcon parentId="media-player-control" />
            ) : null}
            {isBuffering && isPlaying ? (
                <div
                    id="media-player-control-play-overlay-background"
                    data-test-id="media-player-control-play-overlay-background"
                    className={`${overlayIconPositionStyles} pointer-events-none`}>
                    <LoadingIcon shape="circle" size="lg" />
                </div>
            ) : null}
        </div>
    ) : null;

export default PlayOverlay;
