import React, { useState } from "react";
import { ForgeIcon } from "@forge-ui-components/atoms";
import DownloadEmailSummary from "./DownloadEmailSummary.tsx";
import ListenToReport from "./ListenToReport.tsx";
import { ViewMode } from "../constants.ts";

const baseStyles =
    "w-max font-sans text-sm text-black visited:text-black hover:text-black cursor-pointer flex items-center";

type FontMultiplier = 1 | 1.25 | 1.5;

type MoreMenuLabels = {
    fontSizeLabel: string;
    normalLabel: string;
    mediumLabel: string;
    largeLabel: string;
    moreLabel?: string;
    downloadSummaryLabel?: string;
    listenToReportLabel?: string;
};

type MoreMenuProps = {
    setFontMultiplier: (multiplier: FontMultiplier) => void;
    fontMultiplier: FontMultiplier;
    labels: MoreMenuLabels;
    mediaUrl?: string;
    viewMode?: string;
};

const MoreMenu: React.FC<MoreMenuProps> = ({ setFontMultiplier, fontMultiplier, labels, mediaUrl, viewMode }) => {
    const [isFontSizeMenuOpen, setIsFontSizeMenuOpen] = useState<boolean>(false);

    return (
        <div className={`${baseStyles} relative group`} data-testid="menu-button">
            <span>{labels?.moreLabel || "More"}</span>
            <span className="pl-0_5">
                <ForgeIcon icon="more-vert" pxSize={20} color="#000000" />
            </span>

            <div className="hidden group-hover:block bg-transparent absolute top-full right-0 w-max">
                <div
                    id="menu"
                    data-testid="menu-dropdown"
                    className="flex flex-col bg-white w-fit items-center border border-gray-subtle shadow border-1 mt-1">
                    {viewMode !== ViewMode.GSNOW_VIEW_MODE && (
                        <DownloadEmailSummary
                            distributionHeadline="Email Summary"
                            label={labels?.downloadSummaryLabel}
                        />
                    )}
                    <button
                        className="flex items-center w-full px-3 py-1 hover:bg-gray-subtle"
                        onClick={() => setIsFontSizeMenuOpen(!isFontSizeMenuOpen)}
                        type="button">
                        <div className="justify-self-start">
                            <ForgeIcon icon="fontsize" pxSize={20} color="#000000" />
                        </div>
                        <div className="flex-grow text-left pl-1">{labels?.fontSizeLabel || "Font Size"}</div>
                        <div className="justify-self-end text-sm pl-1">{"\u25BE"}</div>
                    </button>
                    {isFontSizeMenuOpen && (
                        <div className="flex flex-col w-full">
                            <button
                                onClick={() => setFontMultiplier(1)}
                                className={`w-full px-3 py-1 hover:bg-gray-subtle ${
                                    (fontMultiplier === 1 && "bg-gray-subtle") || null
                                }`}
                                type="button">
                                {labels?.normalLabel || "Normal"}
                            </button>
                            <button
                                onClick={() => setFontMultiplier(1.25)}
                                className={`w-full px-3 py-1 hover:bg-gray-subtle ${
                                    (fontMultiplier === 1.25 && "bg-gray-subtle") || null
                                }`}
                                type="button">
                                {labels?.mediumLabel || "Medium"}
                            </button>
                            <button
                                onClick={() => setFontMultiplier(1.5)}
                                className={`w-full px-3 py-1 hover:bg-gray-subtle ${
                                    (fontMultiplier === 1.5 && "bg-gray-subtle") || null
                                }`}
                                type="button">
                                {labels?.largeLabel || "Large"}
                            </button>
                        </div>
                    )}
                    {mediaUrl && <ListenToReport label={labels?.listenToReportLabel} mediaUrl={mediaUrl} />}
                </div>
            </div>
        </div>
    );
};

export type { MoreMenuProps, FontMultiplier, MoreMenuLabels };
export default MoreMenu;
