import { Events } from "../EventType.ts";

export const eventPropertyMap = {
    agenda: ["agendaUrl.description", "agendaUrl.url"],
    dialDetails: ["dialIn.phoneNumbers"],
    curatedpage: ["curatedPageUrl.description", "curatedPageUrl.url"],
    registration: ["regUrl.description", "regUrl.url"],
    webcast: ["webcastUrl.description", "webcastUrl.url"],
    weburl: ["webUrl.description", "webUrl.url"],
    speakersText: ["speakersText"],
    password: ["password"],
    hostsText: ["hostsText"],
    dialIn: ["dialIn.passcode"],
    researchText: ["researchText"],
    speakers: ["speakers"],
    hosts: ["hosts"],
    relatedresearch: ["research.headline", "research.url"],
};

export const doesElementExist = (event: Events, properties: string[]) =>
    properties.every((prop) => {
        const value = prop.split(".").reduce((acc: any, key) => {
            if (acc && typeof acc === "object") {
                return acc[key as keyof typeof acc];
            }
            return undefined;
        }, event);
        return value !== undefined && value !== null && value !== "";
    });
