import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DateUtils } from "../utils/dateUtils.ts";

dayjs.extend(utc);
dayjs.extend(timezone);

const DateTime = (event: any) => {
    /**
     * formats the conference date time based on the start and end dates of the report.
     * @param report - the report object containing start, end dates and location.
     * @returns formatted date time string.
     * */
    const getConferenceDateTime = (report: any) => {
        const start = dayjs(report.startDate);
        const end = dayjs(report.endDate);
        const startDate = start.format("D");
        const endDate = end.format("D");
        const startYear = start.format("YYYY");
        const endYear = end.format("YYYY");
        const startMonth = start.format("MMM");
        const endMonth = end.format("MMM");
        const month = start.format("MMM YYYY");

        if (startDate === endDate && startMonth === endMonth) {
            return `${startDate} ${month} | ${report.location}`;
        }
        if (startMonth === endMonth && startYear === endYear) {
            return `${startDate}-${endDate} ${month} | ${report.location}`;
        }
        if (startYear !== endYear) {
            return `${startDate} ${startMonth} ${startYear}-${endDate} ${endMonth} ${endYear} | ${report.location}`;
        }
        return `${startDate} ${startMonth}-${endDate} ${endMonth} ${startYear} | ${report.location}`;
    };

    /**
     * Gets the formatted date and time based on report type.
     * @param report - the report object.
     * @returns formatted date time string.
     */

    const getDateTime = (report: any) => {
        if (report.type === "conference") {
            return getConferenceDateTime(report);
        }
        return DateUtils.getWebCastDateTime(report);
    };

    return <div>{getDateTime(event)}</div>;
};

export default DateTime;
