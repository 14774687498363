"use client";

import { FC, Suspense, lazy } from "react";
import { Button } from "@gs-ux-uitoolkit-react/button";
import { ForgeIcon } from "@forge-ui-components/atoms";
import { useStyleSheet, StyleSheet } from "@gs-ux-uitoolkit-react/style";
import { buttonClasses, errorAlert } from "./styles.ts";
import { Interest } from "../types.ts";
import { LABELS } from "../constants.ts";
import { useFollow } from "../Hooks/useFollow.ts";
import { isAlertingSuspended } from "../utils.ts";

type TagFollowProps = {
    followItem: Interest;
    targetId: string;
    withDropdown: boolean;
    isError?: boolean;
};

const FollowDropdown = lazy(() => import("../FollowDropdown/index.tsx"));

const TagFollowButton: FC<TagFollowProps> = ({ followItem, targetId, withDropdown, isError: isLoadingError }) => {
    const {
        isError,
        interest,
        isFollowLoading,
        distributionChannels,
        handleFollow,
        handleUnFollow,
        onFrequencyChange,
        showBellIcon,
        dropdownVisible,
        hideMenu,
        toggleAlerts,
    } = useFollow(followItem, withDropdown);
    const isAlertSuspended = isAlertingSuspended();

    const overrideStyleSheet = new StyleSheet("button-override", {
        root: { display: "flex !important" },
    });
    const overrideClasses = useStyleSheet(overrideStyleSheet, null);

    return (
        <div className="relative">
            {isError || isLoadingError ? (
                <div role="alert" className={errorAlert}>
                    Something went wrong
                </div>
            ) : null}
            <Button
                data-testid="follow-button"
                id={targetId}
                classes={{ root: overrideClasses.root }}
                className={buttonClasses(interest)}
                onClick={isFollowLoading ? undefined : handleFollow}
                actionType="primary"
                emphasis="bold">
                {interest.followActive ? LABELS.following : LABELS.follow}
                {showBellIcon && (
                    <ForgeIcon
                        icon="notifications"
                        data-testid="bell-icon"
                        pxSize={16}
                        color="#FFFFFF"
                        classNames="ml-1 !p-0"
                    />
                )}
            </Button>
            <Suspense>
                <FollowDropdown
                    target={targetId}
                    visible={dropdownVisible}
                    hideMenu={hideMenu}
                    customInterest={interest.customInterest}
                    tagName={(interest.tag && interest.tag.tagName) || interest.interestName || undefined}
                    interestName={interest.interestName}
                    distributionChannels={distributionChannels}
                    isSuspended={isAlertSuspended}
                    highVolume={interest.highVolume}
                    onFrequencyChange={onFrequencyChange}
                    selectedFrequency={interest.deliveryPreference?.frequency || interest.frequency}
                    onUnfollowButtonClick={handleUnFollow}
                    includeAlerts={interest.includeAlerts || []}
                    toggleAlerts={toggleAlerts}
                />
            </Suspense>
        </div>
    );
};

export default TagFollowButton;
