import React, { useEffect, useState } from "react";
import { ForgeIcon } from "@forge-ui-components/atoms";
import { Slider, SliderChangeEvent } from "@gs-ux-uitoolkit-react/slider";
import { MUTE_VOLUME, SET_VOLUME, UNMUTE_VOLUME } from "../actions.ts";

const IconDisplay = (volumeValue: number) => {
    if (volumeValue > 0.5) {
        return (
            <ForgeIcon
                icon="volume-up"
                dataTestId="high-volume-icon"
                pxSize={17}
                color="#FFFFFF"
                classNames="inline align-middle"
            />
        );
    }
    if (volumeValue === 0) {
        return (
            <ForgeIcon
                icon="mute"
                dataTestId="mute-icon"
                pxSize={17}
                color="#FFFFFF"
                classNames="inline align-middle"
            />
        );
    }
    return (
        <ForgeIcon
            icon="volume-down"
            dataTestId="low-volume-icon"
            pxSize={17}
            color="#FFFFFF"
            classNames="inline align-middle"
        />
    );
};

export type MuteButtonProps = {
    volume: number;
    isMuted: boolean;
    dispatch: React.Dispatch<any>;
};

const MuteButton = ({ volume, isMuted, dispatch }: MuteButtonProps) => {
    const toggleMuted = () => (isMuted ? dispatch({ type: UNMUTE_VOLUME }) : dispatch({ type: MUTE_VOLUME }));

    return (
        <button
            id="media-player-mute-button"
            data-testid="media-player-mute-button"
            onClick={toggleMuted}
            type="button">
            {IconDisplay(volume)}
        </button>
    );
};

export type VolumeControlsProps = {
    volume: number;
    isMuted: boolean;
    isCompact?: boolean;
    isSlim?: boolean;
    dispatch: React.Dispatch<any>;
};

const VolumeControls = ({ volume, isMuted, isCompact, isSlim, dispatch }: VolumeControlsProps) => {
    // Value of 0-100
    const [volumePercentage, setVolumePercentage] = useState(100 * volume);

    useEffect(() => {
        setVolumePercentage(100 * volume);
    }, [volume]);

    const onSliderChange = (event: SliderChangeEvent) => {
        dispatch({ type: SET_VOLUME, payload: event.value / 100 });
    };

    return (
        <div data-testid="media-player-volume-control-container" className="flex mx-1">
            <MuteButton volume={volume} isMuted={isMuted} dispatch={dispatch} />
            {isCompact || isSlim ? null : (
                <div
                    id="media-player-volume-slider"
                    data-testid="media-player-volume-slider"
                    className="max-w-10 min-w-8 ml-0_5 mt-0_5">
                    <Slider
                        data-testid="media-player-volume-interactive-slider"
                        value={volumePercentage}
                        minValue={0}
                        maxValue={100}
                        onChange={onSliderChange}
                    />
                </div>
            )}
        </div>
    );
};

export default VolumeControls;
