import { useState, useRef, useEffect } from "react";
import { RailObject } from "react-compound-slider/dist/types/Rail/types";

type MediaPlayerRailDisplayProps = RailObject & {
    getDisplayValue: Function;
    onSlideStart?: Function;
};

const MediaPlayerRailDisplay = ({
    activeHandleID,
    getRailProps,
    getEventData,
    getDisplayValue,
    onSlideStart,
}: MediaPlayerRailDisplayProps) => {
    const [value, setValue] = useState<number | null>(null);
    const [percent, setPercent] = useState<number | null>(null);
    const [mouseOver, setMouseOver] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const onMouseEnter = () => {
        setMouseOver(true);
    };

    const onMouseLeave = () => {
        setValue(null);
        setPercent(null);
        setMouseOver(false);
    };

    const onMouseMove = (event: any) => {
        if (activeHandleID) {
            setValue(null);
            setPercent(null);
        } else {
            setValue(getEventData(event).value);
            setPercent(getEventData(event).percent);
        }
    };

    useEffect(() => {
        const reference = wrapperRef.current;
        if (reference) {
            reference.addEventListener("mousemove", onMouseMove);
        }

        return () => {
            if (reference) {
                reference.removeEventListener("mousemove", onMouseMove);
            }
        };
    });

    return (
        <div id="media-player-slider-rail" data-testid="media-player-slider-rail" className="w-full" ref={wrapperRef}>
            {!activeHandleID && value && mouseOver && getDisplayValue ? (
                <div
                    id="media-player-slider-rail-tooltip"
                    data-testid="media-player-slider-rail-tooltip"
                    className="absolute translate-x-[-50%] top-[-25px] z-10 ml-[6px] py-0 px-[5px] text-status-information-bold-text bg-gray-bold-active bg-opacity-80"
                    style={{ left: `${percent}%` }}>
                    {getDisplayValue(value)}
                </div>
            ) : null}
            <div
                id="media-player-slider-interactive-rail"
                data-testid="media-player-slider-interactive-rail"
                className="absolute w-full h-0_5 cursor-pointer z-[300]"
                {...getRailProps({
                    onMouseEnter,
                    onMouseLeave,
                    onMouseDown: onSlideStart,
                })}
            />
            <div className="absolute w-full h-0_5 bg-surface-none-text bg-opacity-80 cursor-pointer pointer-events-none" />
        </div>
    );
};

export default MediaPlayerRailDisplay;
