"use client";

import type { Image } from "@forge-cms/models/components";

const ImageTout = ({ imageUrl, linkUrl, altText, width, height, title, description }: Image) => (
    <div>
        <div className="w-fit">
            <a href={linkUrl}>
                <img src={imageUrl} alt={altText} width={width} height={height} title={title} />
            </a>
            {description && <p>{description}</p>}
        </div>
    </div>
);

export default ImageTout;
