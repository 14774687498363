import React from "react";
import { DomElement } from "htmlparser2";
import ReactHtmlParser, { convertNodeToElement, Transform } from "react-html-parser";
import { classListMapping } from "./ClassMapping.ts";
import { ExhibitReference } from "./types.ts";

export type RenderToutProps = {
    componentString: string;
    exhibitReference: ExhibitReference;
};

export const getCompanyTickerUrl = (companyGuid: string) => `/content/research/companies/${companyGuid}.html`;

const replaceClasses = (node: DomElement) => {
    if (node.attribs && node.attribs.class) {
        const classes = node.attribs.class.split(" ");
        const updatedClasses = classes.map((className: string) => classListMapping[className] || className);
        // eslint-disable-next-line no-param-reassign
        node.attribs.class = updatedClasses.join(" ");
    }
    return node;
};

const defaultTransform: Transform = (node, index) => {
    if (node.type === "tag") {
        // eslint-disable-next-line no-param-reassign
        node = replaceClasses(node);
    }
    return convertNodeToElement(node, index, defaultTransform);
};

const priceStripNodeTransform = (exhibitReference: ExhibitReference): Transform =>
    function transformNode(node, index): React.ReactElement | null {
        let updatedNode = node;
        const { type, name, attribs, children } = node;
        if (type === "tag") {
            updatedNode = replaceClasses(node);
        }
        if (name === "span" && attribs && attribs.class === "line-value" && index === 1) {
            return (
                <span className="line-value">
                    {exhibitReference.companyGuid ? (
                        <a
                            className="text-blue-600"
                            href={getCompanyTickerUrl(exhibitReference.companyGuid)}
                            target="_blank"
                            rel="noopener noreferrer">
                            {children?.[0]?.data}
                        </a>
                    ) : (
                        children?.[0]?.data
                    )}
                </span>
            );
        }
        return convertNodeToElement(updatedNode, index, priceStripNodeTransform(exhibitReference));
    };

const getExhibitFragmentTransformer = (type: string, exhibitReference: ExhibitReference): Transform => {
    switch (type) {
        case "priceStripExhibit":
            return priceStripNodeTransform(exhibitReference);
        default:
            return defaultTransform;
    }
};

const RenderTout: React.FC<RenderToutProps> = ({ componentString, exhibitReference }) => {
    const { type } = exhibitReference;

    const parsedComponent = ReactHtmlParser(componentString, {
        transform: getExhibitFragmentTransformer(type, exhibitReference),
    });

    return parsedComponent;
};

export default RenderTout;
