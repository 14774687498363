import { useState } from "react";
import { MAX_CHARACTER_LENGTH } from "../constant.ts";

export type EventDescriptionProps = {
    description: string | null;
};

const EventDescription = ({ description }: EventDescriptionProps) => {
    const [expanded, setExpanded] = useState(false);

    const truncateBlock = (reportDesc: string | null) => {
        if (!reportDesc) return null;
        if (reportDesc.length <= MAX_CHARACTER_LENGTH) return reportDesc;
        const truncatedText = reportDesc.substring(0, MAX_CHARACTER_LENGTH);
        const lastSpaceIndex = truncatedText.lastIndexOf(" ");
        const truncatedBlock = lastSpaceIndex > 0 ? truncatedText.substring(0, lastSpaceIndex) : truncatedText;
        return (
            <span className="font-normal">
                {truncatedBlock}...
                <a
                    className="text-blue-bold hover:underline"
                    role="button"
                    tabIndex={0}
                    aria-label="Read more"
                    onClick={() => setExpanded(true)}
                    onKeyUp={(e) => e.key === "Enter" && setExpanded(true)}>
                    Read more
                </a>
            </span>
        );
    };
    return (
        <div className="text-sm font-normal pb-[10px] break-words hyphens-auto">
            {expanded ? description : truncateBlock(description)}
        </div>
    );
};
export default EventDescription;
