import { StyleSheet } from "@gs-ux-uitoolkit-react/style";

export const overrideStyleSheet = new StyleSheet("tabs-override", {
    root: {
        ul: {
            borderBottom: "1px solid #186ade !important",
        },
    },
    container: {
        li: {
            padding: "0 0.5rem",
        },
        ".nav-link": {
            fontSize: "13px !important",
            fontWeight: 600,
        },
    },
    content: {},
});
