import React, { PropsWithChildren } from "react";
import { HeadingTypography, TypographyColor } from "@gs-ux-uitoolkit-common/design-system";

type Extends<T, U extends T> = U;
type SupportedHeadingTypographies = Extends<
    HeadingTypography,
    "heading01" | "heading02" | "heading03" | "heading04" | "heading05"
>;
type SupportedColors = Extends<TypographyColor, "primary" | "secondary" | "reversed">;

const typographyToTailwindClassMap: Record<SupportedHeadingTypographies, string> = {
    heading01: "font-sans font-medium text-[40px]/[52px]",
    heading02: "font-sans font-medium text-[32px]/[40px]",
    heading03: "font-sans font-medium text-[24px]/[32px]",
    heading04: "font-sans font-medium text-[20px]/[28px]",
    heading05: "font-sans font-medium text-[16px]/[24px]",
};

const htmlTagMappings: Record<SupportedHeadingTypographies, keyof JSX.IntrinsicElements> = {
    heading01: "h1",
    heading02: "h2",
    heading03: "h3",
    heading04: "h4",
    heading05: "h5",
};

const colorToTailwindClassMap: Record<SupportedColors, string> = {
    primary: "text-text-primary",
    secondary: "text-text-secondary",
    reversed: "text-white",
};

type HeadingProps = PropsWithChildren<{
    typography?: keyof typeof typographyToTailwindClassMap;
    color?: SupportedColors;
    className?: string;
    id?: string;
    "data-testid"?: string;
}>;

const Heading: React.FC<HeadingProps> = ({
    typography = "heading02",
    color = "primary",
    className,
    id,
    children,
    ...props
}: HeadingProps) => {
    const typographyClasses = typographyToTailwindClassMap[typography];
    const colorClasses = colorToTailwindClassMap[color as SupportedColors];
    const HtmlTag = htmlTagMappings[typography];

    return (
        <HtmlTag
            id={id}
            className={`${typographyClasses} ${colorClasses} ${className || ""}`}
            data-testid={props["data-testid"]}>
            {children}
        </HtmlTag>
    );
};

export type { HeadingProps, SupportedHeadingTypographies, SupportedColors };
export default Heading;
