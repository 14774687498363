import React from "react";
import PlayTimeDisplay, { PlayTimeDisplayProps } from "./PlayTimeDisplay.tsx";
import PlayControls, { PlayControlsProps } from "./PlayControls.tsx";
import SettingsControls, { SettingsControlsProps } from "./SettingsControls.tsx";
import VolumeControls, { VolumeControlsProps } from "./VolumeControls.tsx";
import MediaPlayerSlider, { MediaPlayerSliderProps } from "./slider/Slider.tsx";
import FullscreenControls, { FullScreenControlsProps } from "./FullscreenControls.tsx";
import PlayOverlay, { PlayOverlayProps } from "./PlayOverlay.tsx";

type ControlBarProps = MediaPlayerSliderProps &
    PlayTimeDisplayProps &
    PlayControlsProps &
    SettingsControlsProps &
    VolumeControlsProps &
    FullScreenControlsProps &
    PlayOverlayProps & {
        mediaPlayerId: string;
        dispatch: React.Dispatch<any>;
    };

/**
 * Media Player Control Bar for users to interact and adjust the media player experience
 * @param mediaPlayerId
 * @param currentTime
 * @param duration
 * @param isPlaying
 * @param playbackSpeed
 * @param setPlaybackSpeed
 * @param onFullScreenButtonClick
 * @param volume
 * @param isMuted
 * @param sliderTimeValue
 * @param sliderMaxValue
 * @param sliderMinValue
 * @param sliderStepSize
 * @param onSlideStart
 * @param onSlideEnd
 * @param getDisplayValue
 * @param isBuffering
 * @param displayPreview
 * @param shouldShowOverlay
 * @param isFullScreen
 * @param isCompact
 * @param isSlim
 * @param fireMetricsEvent
 * @param dispatch
 * @constructor
 */
const ControlBar = ({
    mediaPlayerId,
    currentTime,
    duration,
    isPlaying,
    playbackSpeed,
    onFullScreenButtonClick,
    volume,
    isMuted,
    sliderTimeValue,
    sliderMaxValue,
    sliderMinValue,
    sliderStepSize,
    onSlideStart,
    onSlideEnd,
    getDisplayValue,
    isBuffering,
    displayPreview,
    shouldShowOverlay,
    isFullScreen,
    isCompact,
    isSlim,
    fireMetricsEvent,
    dispatch,
    mediaType,
}: ControlBarProps) => (
    <div
        id="media-player-control-bar-container"
        data-testid="media-player-control-bar-container"
        className="absolute w-full h-full flex bottom-0 left-0 flex-col">
        <PlayOverlay
            isBuffering={isBuffering}
            isPlaying={isPlaying}
            displayPreview={displayPreview}
            shouldShowOverlay={shouldShowOverlay}
            fireMetricsEvent={fireMetricsEvent}
            dispatch={dispatch}
            mediaType={mediaType}
        />
        <MediaPlayerSlider
            sliderTimeValue={sliderTimeValue}
            sliderMinValue={sliderMinValue}
            sliderMaxValue={sliderMaxValue}
            sliderStepSize={sliderStepSize}
            onSlideStart={onSlideStart}
            onSlideEnd={onSlideEnd}
            getDisplayValue={getDisplayValue}
        />
        <div
            id="media-player-control-bar-wrapper"
            data-testid="media-player-control-bar-wrapper"
            className="flex justify-between text-status-information-bold-text bg-surface-contrast-active h-5 px-1">
            <div
                id="media-player-control-bar-actions"
                data-testid="media-player-control-bar-actions"
                className="flex items-center">
                <PlayControls isPlaying={isPlaying} fireMetricsEvent={fireMetricsEvent} dispatch={dispatch} />
                <PlayTimeDisplay currentTime={currentTime} duration={duration} />
                <VolumeControls
                    volume={volume}
                    isMuted={isMuted}
                    isSlim={isSlim}
                    isCompact={isCompact}
                    dispatch={dispatch}
                />
            </div>
            <div
                id="media-player-control-bar-settings"
                data-testid="media-player-control-bar-settings"
                className="flex items-center">
                <SettingsControls mediaPlayerId={mediaPlayerId} playbackSpeed={playbackSpeed} dispatch={dispatch} />
                <FullscreenControls
                    isFullScreen={isFullScreen}
                    isCompact={isCompact}
                    onFullScreenButtonClick={onFullScreenButtonClick}
                />
            </div>
        </div>
    </div>
);

export default ControlBar;
