type PasscodeProps = {
    code: string;
};
const Passcode = ({ code }: PasscodeProps) => (
    <div className=" pb-px">
        <span className="font-bold text-sm leading-5"> Passcode: </span>
        <span className="text-sm font-normal leading-5">{code}</span>
    </div>
);

export default Passcode;
