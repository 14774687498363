"use client";

import React, { useState, useContext, useEffect } from "react";
import { ForgeIcon } from "@forge-ui-components/atoms";
import { setBookmark, removeBookmark } from "@forge-ui-components/funl-client-data-loader";
import { BookmarkGlossaryContext } from "@forge-ui-components/context-providers";
import { producePanamaEvent } from "@forge-ui-components/panama-data-emitter";

export type BookmarkActionProps = {
    bookmarkType: "report" | "media" | "pages";
    assetPath: string;
    existingBookmarkId?: string;
    existingAssetId?: string;
    existingBookmarkPath?: string;
    label?: string;
    className?: string;
};

export type Bookmark = {
    bookmarkId: string;
    bookmarkType: string;
    bookmarkResponseContext: any;
    createdDate: number;
    userGuid: string;
};

const baseStyles = "w-max font-sans text-sm text-black visited:text-black hover:text-black flex items-center";
const BOOKMARK_EVENT_TYPE = "BookmarkAction";
const BOOKMARK_PANAMA_CREATE_EVENT = "create";
const BOOKMARK_PANAMA_DELETE_EVENT = "delete";

/**
 * Utility function that retrieves the assetId from the assetPath with the assumption the assetId is the last part of the path.
 * @param assetPath
 */
const retrieveAssetId = (assetPath: string) => assetPath.substring(assetPath.lastIndexOf("/") + 1).split(".")[0];

const generateBookmarkPanamaEvent = (
    action: string,
    bookmarkId: string,
    bookmarkType: string,
    assetId: string,
    bookmarkPath: string
) => ({
    action,
    actionLocation: window.location.pathname,
    origin: window.location.origin,
    bookmarkInfo: {
        bookmarkId,
        bookmarkType,
        assetId,
        bookmarkPath,
    },
});

const getBookmarkIcon = (bookmarkId: string) =>
    bookmarkId ? (
        <ForgeIcon dataTestId="bookmark-action-button-icon-filled" icon="bookmark-fill" pxSize={20} color="#33333" />
    ) : (
        <ForgeIcon dataTestId="bookmark-action-button-icon-empty" icon="bookmark" pxSize={20} color="#000000" />
    );

const loadingBookmarkIcon = () => (
    <span data-testid="loading-bookmark-icon" className="animate-pulse">
        <ForgeIcon dataTestId="bookmark-action-button-loading" icon="bookmark" pxSize={20} color="#555555" />
    </span>
);

const formatBookmarkPath = (assetPath?: string): string => {
    if (assetPath && typeof assetPath === "string") {
        return assetPath.includes(".html") ? assetPath : `${assetPath}.html`;
    }
    return "";
};

/**
 * BookmarkAction component to add and remove Research Preference Bookmarks.
 * On interaction with the label or Icon, the component will toggle state.
 * During the state change, the component will call the setBookmark or removeBookmark API
 * and produce a Panama Event associated to the API called.
 * @param existingAssetId       - String value of the unique asset Id
 * @param existingBookmarkId    - String value of the unique bookmark Id
 * @param existingBookmarkPath  - String of the url path for the bookmarked content provided by an existing bookmark
 * @param assetPath             - optional String of the url path for the asset
 * @param bookmarkType          - String of value report, media, pages
 * @param label                 - optional String value of display label
 * @param className             - optional additional styling classnames
 * @constructor
 */
const BookmarkAction: React.FC<BookmarkActionProps> = ({
    existingAssetId,
    existingBookmarkId,
    existingBookmarkPath,
    assetPath,
    bookmarkType,
    label = "Bookmark",
    className,
}) => {
    const { bookmarkGlossaryContextState } = useContext(BookmarkGlossaryContext);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const bookmarkPath = existingBookmarkPath
        ? formatBookmarkPath(existingBookmarkPath)
        : formatBookmarkPath(assetPath);
    const assetId = existingAssetId || retrieveAssetId(assetPath);
    const [bookmarkId, setBookmarkId] = useState<string>(
        existingBookmarkId ||
            (bookmarkGlossaryContextState && bookmarkGlossaryContextState[bookmarkPath]?.bookmarkId) ||
            ""
    );

    useEffect(() => {
        setBookmarkId((bookmarkGlossaryContextState && bookmarkGlossaryContextState[bookmarkPath]?.bookmarkId) || "");
    }, [bookmarkPath, bookmarkGlossaryContextState]);

    const persistBookmark = async () => {
        let persistedBookmarkId: string = bookmarkId || assetId;
        try {
            const response = await setBookmark({ assetId, type: bookmarkType });
            producePanamaEvent(
                BOOKMARK_EVENT_TYPE,
                generateBookmarkPanamaEvent(
                    BOOKMARK_PANAMA_CREATE_EVENT,
                    response.bookmarkId,
                    bookmarkType,
                    assetId,
                    bookmarkPath
                )
            );
            persistedBookmarkId = response.bookmarkId;
        } finally {
            setBookmarkId(persistedBookmarkId);
        }
    };

    const deleteBookmark = async () => {
        try {
            await removeBookmark({ bookmarkId });
            producePanamaEvent(
                BOOKMARK_EVENT_TYPE,
                generateBookmarkPanamaEvent(
                    BOOKMARK_PANAMA_DELETE_EVENT,
                    bookmarkId,
                    bookmarkType,
                    assetId,
                    bookmarkPath
                )
            );
        } finally {
            setBookmarkId("");
        }
    };

    const handleBookmarkChange = async () => {
        try {
            setIsProcessing(true);
            if (bookmarkId) await deleteBookmark();
            else await persistBookmark();
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <button
            data-testid="bookmark-action-button"
            type="button"
            onClick={handleBookmarkChange}
            className={`${baseStyles} ${className || null}`}>
            <span className="no-underline hover:underline">{label}</span>
            <span className="pl-0_5">{isProcessing ? loadingBookmarkIcon() : getBookmarkIcon(bookmarkId)}</span>
        </button>
    );
};

export default BookmarkAction;
