"use client";

import { HighchartsReact } from "highcharts-react-official";
import Highcharts from "highcharts";

type InteractiveExhibitDisplayProps = {
    /** Class name to be used for the interactive exhibit asset * */
    className: string;

    /** Base64 encoded data used to render the interactive exhibit asset * */
    data?: string;

    /** Should be provided if no data is available, used to render a static image instead * */
    staticImageSrc?: string;
};

/**
 * Component which renders an interactive exhibit.
 */
const InteractiveExhibitDisplay: React.FC<InteractiveExhibitDisplayProps> = ({
    data,
    staticImageSrc,
    className,
}: InteractiveExhibitDisplayProps) => (
    <div className={className} data-testid="exhibitAsset">
        {!data ? (
            <img src={staticImageSrc} alt="Interactive Exhibit" />
        ) : (
            <HighchartsReact
                highcharts={Highcharts}
                options={JSON.parse(Buffer.from(data, "base64").toString("utf-8"))}
            />
        )}
    </div>
);

export type { InteractiveExhibitDisplayProps };
export default InteractiveExhibitDisplay;
