import RenderTout from "./RenderTout.tsx";
import { ExhibitReference } from "./types.ts";

/**
 * Converts an array of `ExhibitReference` objects into a key-value pair object.
 * The keys of the resulting object are the `type` properties of the `ExhibitReference` objects.
 * The values of the resulting object are the `ExhibitReference` objects themselves.
 *
 * @param exhibitReferences - An array of `ExhibitReference` objects.
 * @returns A key-value pair object where the keys are the `type` properties of the `ExhibitReference` objects,
 * and the values are the `ExhibitReference` objects themselves.
 */

export function convertExhibitReferencesToObject(
    exhibitReferences: ExhibitReference[]
): Record<string, ExhibitReference> {
    const exhibitReferencesObject: Record<string, ExhibitReference> = {};
    exhibitReferences.forEach((exhibitReference) => {
        exhibitReferencesObject[exhibitReference.type] = exhibitReference;
    });
    return exhibitReferencesObject;
}

/**
 * Renders an exhibit part based on the provided exhibit reference snippet.
 * The exhibit reference snippet is decoded from base64 and parsed as JSON.
 * The parsed JSON contains html string, which further passed to RenderTout component.
 * If the exhibit type is 'priceStripExhibit', the first span element with the class 'line-value'
 * is hyperlinked to the company URL based on the company GUID.
 *
 * @param exhibitReference - The exhibit reference containing the data to render.
 * @returns The rendered exhibit snippet html string.
 */

export const renderExhibitPart = (exhibitReference: ExhibitReference) => {
    const { data } = exhibitReference;
    const decodeString = Buffer.from(data, "base64").toString("utf-8");
    const componentString = JSON.parse(decodeString)?.mochaX?.exhibit?.html || "";

    return (
        <RenderTout key={exhibitReference.type} componentString={componentString} exhibitReference={exhibitReference} />
    );
};

/**
 * Unescapes a string by decoding it from base64 and converting special characters.
 *
 * @param escapedString - The escaped string to unescape.
 * @returns The unescaped string.
 */
export function unescapeString(escapedString: string): string {
    const unescapedString = escapedString
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'");
    return unescapedString;
}
