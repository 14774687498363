"use client";

import { UserProfileItem } from "@forge-cms/models/components";
import { useContext, useEffect, useState } from "react";
import { ContactProfileContext } from "@forge-ui-components/context-providers";
import { ForgeIcon } from "@forge-ui-components/atoms";

type UserInfoProps = {
    logoutUrl: string;
    items: UserProfileItem[];
    policyEnv: "uat" | "prod";
};

const UserInfo = ({ items, logoutUrl, policyEnv }: UserInfoProps) => {
    const [isExpanded, setExpanded] = useState(false);
    const [hasInternalTool, setHasInternalTool] = useState(false);
    const { contactProfileContextState } = useContext(ContactProfileContext);

    useEffect(() => {
        if (contactProfileContextState) {
            const { entitlementRoles } = contactProfileContextState;
            const internalToolRole = `com.gs.res.distribution.cpt.${policyEnv}:Internal_Tool`.toLowerCase();

            if (
                entitlementRoles?.some((entitlementRole: string) => entitlementRole.toLowerCase() === internalToolRole)
            ) {
                setHasInternalTool(true);
            }
        }
    }, [contactProfileContextState, policyEnv]);

    const logoutUser = async () => {
        const authCookies = [
            {
                name: "access_token",
                domains: ["publishing.gs.com", "publishing-dev.gir.site.gs.com", "publishing-qa.gs.com"],
            },
        ];

        setExpanded(false);

        // Reset cookies
        authCookies.forEach((cookie) => {
            if (document.cookie.includes(cookie.name)) {
                cookie.domains.forEach((domain) => {
                    document.cookie = `${cookie.name}=;max-age=0;path=/;domain=${domain};Thu, 01 Jan 1970 00:00:00 GMT;`;
                });
            }
        });

        // Clear local storage
        localStorage.clear();

        await fetch("/services/logout", { credentials: "include" }).then(async (response) => {
            if (response.status === 200) {
                await fetch(logoutUrl, { credentials: "include" }).then(() => {
                    window.location.assign("/");
                });
            }
        });
    };

    const userItems = hasInternalTool
        ? [...items, { name: "Internal Tools", link: "/content/research/site/internal-tools.html" }]
        : items;

    return (
        <div className="my-auto relative">
            <button
                className="flex gap-1"
                type="button"
                onClick={() => setExpanded(!isExpanded)}
                aria-label="User Info">
                <div className="h-[27.5px] w-[27.5px] rounded-[50%] border border-black">
                    <ForgeIcon icon="person" pxSize={25} color="#000000" />
                </div>{" "}
                <span className="hidden sm:block">Profile</span>
            </button>
            {isExpanded && (
                <ul className="absolute bg-white whitespace-nowrap rounded-[3px] border border-[#dce3e8] min-w-[150px] left-[-150px] sm:left-[-35px] top-[35px] z-10 py-[10px] px-[15px] text-left">
                    {userItems.map((item) => (
                        <li>
                            <a
                                className="text-black text-[14px] font-normal visited:text-black hover:text-[#0d4da6]"
                                href={item.link}>
                                {item.name}
                            </a>
                        </li>
                    ))}
                    <li>
                        <a
                            href="#"
                            className="text-black text-[14px] font-normal visited:text-black hover:text-[#0d4da6]"
                            onClick={logoutUser}>
                            Log Out
                        </a>
                    </li>
                </ul>
            )}
        </div>
    );
};

export type { UserInfoProps };
export default UserInfo;
