import { ScriptProps } from "next/script";

export type LegacyScript = "ntk" | "core-frontend" | "rps" | "mq";

export type WebComponentMapping = {
    componentName: string;
    propertyMap: Record<string, string>;
    scriptDependency: LegacyScript;
};

export const webComponentMappings: Record<string, WebComponentMapping> = {
    SearchBar: {
        componentName: "react-search-bar",
        propertyMap: {
            url: "url",
            lang: "lang",
            action: "action",
            trackingUrl: "tracking-url",
            simpleSearchTrackingToggle: "simple-search-tracking-toggle",
            simpleSearchPersonalizationToggle: "simple-search-personalization-toggle",
            domains: "domains",
            excludeSourcePath: "exclude-source-path",
        },
        scriptDependency: "core-frontend",
    },
    MarqueeHeader: {
        componentName: "mq-header",
        propertyMap: {
            noPlaceholder: "no-placeholder",
            class: "class",
        },
        scriptDependency: "mq",
    },
};

export const webComponentScriptMappings: Record<LegacyScript, ScriptProps[]> = {
    ntk: [
        {
            src: "/etc/designs/research/ntk/numbers-toolkit.min.js",
        },
    ],
    "core-frontend": [
        {
            src: "/etc/designs/research/clientlibs/js/commons.dll.js",
            strategy: "beforeInteractive",
            defer: true,
        },
        {
            src: "/etc/designs/research/clientlibs/js/forge-legacy.min.js",
            strategy: "beforeInteractive",
            defer: true,
        },
    ],
    rps: [
        {
            src: "/etc/designs/research/research-preferences-web/preferences-app.js",
        },
    ],
    mq: [
        {
            src: "/etc/designs/mq/mq-components/mq-components.min.js",
            strategy: "beforeInteractive",
        },
    ],
};
