import { GetTrackProps } from "react-compound-slider/dist/types/Tracks/types";

export type MediaPlayerSliderTrackerProps = {
    getTrackProps: GetTrackProps;
    sourcePercentage: number;
    targetPercentage: number;
};
const MediaPlayerSliderTracker = ({
    sourcePercentage,
    targetPercentage,
    getTrackProps,
}: MediaPlayerSliderTrackerProps) => (
    <div
        id="media-player-slider-tracker"
        data-testid="media-player-slider-tracker"
        className="cursor-pointer absolute h-0_5 z-5 bg-gray-subtle-active"
        style={{
            left: `${sourcePercentage}%`,
            width: `${targetPercentage - sourcePercentage}%`,
        }}>
        <div className="hidden">{JSON.stringify(getTrackProps())}</div>
    </div>
);

export default MediaPlayerSliderTracker;
