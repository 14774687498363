"use client";

import { useMemo, useState } from "react";
import { useStyleSheet } from "@gs-ux-uitoolkit-react/style";
import { ContentCard } from "@forge-ui-components/atoms";
import { Tabs, Tab } from "@gs-ux-uitoolkit-react/tabs";
import { EventTab, QueryComponentTitle } from "@forge-cms/models/components/index";
import { overrideStyleSheet } from "./Styles.tsx";
import EventPanelList from "./EventPanelList.tsx";

export type EventTabProps = {
    tabs?: EventTab[];
    queryResponse: any;
    title?: QueryComponentTitle;
};

const EventTabs = ({ tabs, queryResponse, title }: EventTabProps) => {
    const [activeTab, setActiveTab] = useState(0);

    const tabDataMap = useMemo(() => {
        const map = new Map<number, any>();
        queryResponse?.forEach((item: any, index: number) => {
            map.set(index, item);
            return map;
        });
        return map;
    }, [queryResponse]);

    const onTabSelect = (key: number) => {
        setActiveTab(key);
    };

    const overrideClasses = useStyleSheet(overrideStyleSheet, null);

    const tabData = tabDataMap.get(activeTab);
    const activeReports = tabData?.reports || [];
    const viewMoreEnabled = tabs?.[activeTab]?.events?.viewMore?.enabled;
    const viewMoreLink = tabs?.[activeTab]?.events?.viewMore?.viewMoreLink || tabData?.viewMoreLink;
    const viewMoreButtonText = tabs?.[activeTab]?.events?.viewMore?.viewMoreButtonText || "View More";

    return (
        <ContentCard
            title={title?.title}
            titleStyle={title?.titleStyle}
            footerLinkLabel={viewMoreEnabled ? viewMoreButtonText : undefined}
            footerLinkUrl={viewMoreEnabled ? viewMoreLink : undefined}>
            <div className="px-2_5">
                <Tabs
                    variant="tabs"
                    activeTabKey={activeTab}
                    onSelect={onTabSelect}
                    size="md"
                    classes={overrideClasses}>
                    {tabs?.map((item: EventTab, index: number) => (
                        <Tab tabKey={index} title={item?.events?.name?.toUpperCase()}>
                            <EventPanelList reports={activeReports} formOrder={item.events?.displayFields} />
                        </Tab>
                    ))}
                </Tabs>
            </div>
        </ContentCard>
    );
};

export default EventTabs;
