"use client";

import { ForgeIcon } from "@forge-ui-components/atoms";
import useDetectScroll from "@smakss/react-scroll-direction";
import classNames from "classnames";
import { useState } from "react";
import ActionBarButtons from "./ActionBarButtons.tsx";
import TableOfContents, { ChapterNavigationDetails } from "./TableOfContents.tsx";
import { ReportActionBarLabels } from "./types.ts";

export type ReportNavigationProps = {
    publicationDateTime: string;
    chapterNavigationData: ChapterNavigationDetails[];
    distributionHeadline: string;
    pdfPath: string;
    htmlPath: string;
    baseUrl: string;
    sourceDiscipline: string;
    leadAuthorDisplayText: string;
    viewMode: string;
    labels: ReportActionBarLabels;
    mediaUrl?: string;
};

const ReportNavigation: React.FC<ReportNavigationProps> = ({
    publicationDateTime,
    chapterNavigationData,
    distributionHeadline,
    pdfPath,
    htmlPath,
    baseUrl,
    sourceDiscipline,
    leadAuthorDisplayText,
    viewMode,
    labels,
    mediaUrl,
}: ReportNavigationProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const { scrollPosition } = useDetectScroll();

    const gridClass = classNames({
        "grid grid-cols-16 z-40 text-base": true,
        "bg-gray-subtle-background": isExpanded,
        "bg-white": !isExpanded,
        "border-b-2 border-t-2": scrollPosition.top > 300,
    });

    const actionMenuClass = classNames({
        "col-span-16 sm:col-span-5 md:col-span-6 lg:col-span-5 col-span-5": true,
        "col-start-1 sm:col-start-12 md:col-start-11 lg:col-start-12": true,
        "items-center justify-end": true,
        "sm:px-2 md:px-4 lg:px-6 px-6": true,
        "sm:justify-start": !isExpanded,
        "sm:justify-end": isExpanded,
    });

    return (
        <div className={gridClass} data-testid="report-navigation">
            <div className="col-span-16 col-start-1">
                <div>
                    <TableOfContents
                        chapterNavigationData={chapterNavigationData}
                        setIsExpanded={setIsExpanded}
                        isExpanded={isExpanded}
                        tocLabel={labels?.tocLabel}
                    />
                </div>
            </div>
            <div className="grid grid-cols-16 col-span-16 col-start-0 py-2_5">
                <div className="col-span-16 col-start-1 sm:col-span-10 sm:col-start-1 px-4 lg:pl-[5.75rem] z-10">
                    <div>
                        <div data-testid="contents-title-bar" className="font-sans font-normal flex items-center">
                            {chapterNavigationData.length > 1 && (
                                <button
                                    type="button"
                                    onClick={() => setIsExpanded(!isExpanded)}
                                    aria-label={isExpanded ? "Close Table of Contents" : "Open Table of Contents"}
                                    className="bg-black text-white rounded-full p-0_25 cursor-pointer mr-2">
                                    <ForgeIcon icon="list" pxSize={20} color="#ffff" />
                                </button>
                            )}
                            <span data-testid="contents-title">
                                {`${publicationDateTime} | ${sourceDiscipline}`}
                                <span
                                    id="report-navigation-lead-author-display-text"
                                    data-testid="report-navigation-lead-author-display-text"
                                    className="md:hidden">
                                    | <a href="#author-list">{leadAuthorDisplayText}</a>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className={actionMenuClass}>
                    <div className="pt-2 sm:pt-0">
                        <ActionBarButtons
                            distributionHeadline={distributionHeadline}
                            pdfPath={pdfPath}
                            htmlPath={htmlPath}
                            baseUrl={baseUrl}
                            viewMode={viewMode}
                            labels={labels}
                            mediaUrl={mediaUrl}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportNavigation;
