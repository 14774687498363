"use client";

import tracer from "@forge-ui-components/tracing-client";
import React from "react";

const LOG_CONTEXT = { moduleName: "ForgeErrorBoundary" };

type ForgeErrorBoundaryProps = {
    boundaryType: "component" | "section";
    reference: string;
    propagate: boolean;
    fallback: React.ReactNode;
    children: React.ReactNode;
    classNames?: string;
};

type ForgeErrorBoundaryState = {
    error: Error | null;
};

class ForgeErrorBoundary extends React.Component<ForgeErrorBoundaryProps, ForgeErrorBoundaryState> {
    constructor(props: ForgeErrorBoundaryProps) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error: Error) {
        return { error };
    }

    componentDidCatch(error: Error): void {
        tracer.log.error(
            `Failed to render boundaryType=${this.props.boundaryType} reference=${this.props.reference} with propagateConfig=${this.props.propagate}`,
            LOG_CONTEXT,
            error
        );
        if (this.props.propagate) {
            throw this.state.error;
        }
    }

    render(): React.ReactNode {
        if (this.state.error) {
            return this.props.fallback;
        }
        return (
            <div className={this.props.classNames}>
                {/* Do not remove this div - required for error boundaries to function as expected */}
                {this.props.children}
            </div>
        );
    }
}

export type { ForgeErrorBoundaryProps };
export { ForgeErrorBoundary };
export default ForgeErrorBoundary;
