export const classListMapping: Record<string, string> = {
    "mocha-price-strip":
        "mocha-price-strip w-full text-left sm:text-center flex-col items-start sm:flex-row sm:items-center [&>tr]:flex [&>tr]:flex-col sm:[&>tr]:flex-row [&>tr>td]:w-full [&>tr>td]:sm:border-r-2 [&>tr>td]:sm:border-[#ffffff] [&>tr>td]:max-sm:border-x-[6px] [&>tr:not(:last-child)]:border-b-[0.75rem] [&>tr]:border-[#ffffff] [&>tr>td:last-child]:border-r-0 [&>tr>td]:py-[6px] [&>tr>td>.line-value]:font-bold",
    numeric: "numeric text-right",
    "mocha-data-table": "mocha-data-table w-full min-w-[420px]",
    "company-tout__forecast__title": "company-tout__forecast__title text-[#7399c6]",
    emphasis: "emphasis font-bold",
    "company-tout__heading": "flex company-tout__heading bg-[#49B848] text-[#FFFFFF] text-[31px] leading-none",
    "icon-image--conviction-list":
        "icon-image--conviction-list inline-block w-[24px] h-[24px] bg-[length:24px_24px] bg-[url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41Ljg3O4BdAAACL0lEQVRYR72XLXTCMBDHkUgcOHDMDQcO3OaKYw4cOHAgcZVIJLISiaysRCIrkUgct/8lx+sGaSCl5fferfm43F0+LiylLBBRBdK7kZp0F8PlcvmGrCAxnBlB3xGyQbEPKcvQ14ChHozu2YELHAw+IzHjDgZXYGSnrL2ABP8hZp+DB2Bg6lK7AlsnfHpi3g4U2TkPKIK+uDEDBV723GZ+C2yf8UnfDiiEWrU4ZIL3GYJGTp13sRC3CYjMOdWyIluRXFyovHP2V5biXs2eby8LJ9pvpuS16wR1SJ3a3pSCQ5Isx8DTfX4kLXbgM1LOGVT41kohpmBQhfEqDYKYeO3oFJHf5kDa5EeqxTkAoabyXpfNxJsvbXi4xTokxFeH451qzxhA/8H+Y/Yez7RE4539bsoYwIIDGOmyCSw1G4U8spsxgCXv/0QqBt4TwI9UDMS06eoApqE+bGlkDgB/WrpsZr/6VIar81BngJDHIeTJcwBlFNKnd05Sbr6TbD2FeaVhC2PUPbCVBjPnA239IXWbfB/ASbVJ3aFPW9NFZBAvuL9m4DNGnwZ1SyYUAwJYi3sVQA0N9kTPH738VxDATDoKh7dc3CagnQ+j5TchH+CDT21D3P4HnR1RKBL7v+qsoPXyB5NbiRs7UMz9PMBmcuqfAWP4RfRyZvCWQiZi1g2M5/RcaVPuYCxfcG6vIhMw0oCxNeThmwE61wfqcy8hV2CYX078E778K2ibQTqi9iSl0i8ReqNDyN8X1QAAAABJRU5ErkJggg==)]",
    "company-tout__rating": "company-tout__rating pr-[7px]",
    "company-tout__rating__title": "company-tout__rating__title flex items-center",
};
