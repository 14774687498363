import { useEffect, useState } from "react";
import AdobeAnalyticsDataEmitter from "@forge-ui-components/adobe-analytics-data-emitter";
import { MediaPlayerState, MediaEventData } from "../types.ts";
import {
    MEDIA_PLAYER_ADOBE_ANALYTIC_EVENT_TYPE,
    PLAYING,
    REAL_PLAYED_25_PERCENT,
    REAL_PLAYED_50_PERCENT,
    REAL_PLAYED_75_PERCENT,
    REAL_PLAYED_90_PERCENT,
} from "../constants.ts";
import { formatSeconds } from "../utils/format.ts";

// To Do: Add Analytics Hook Unit Tests
const dispatchAdobeAnalyticsMediaEvent = ({
    eventType,
    mediaName,
    mediaType,
    mediaAkamaiPath,
    mediaActiveTimeInSec,
    mediaCurrentTimeInSec,
    mediaFullDurationInSec,
    mediaDivId,
    mediaWidth,
    mediaHeight,
    mediaCurrentTimeInHHMMSS,
    mediaFullDurationInHHMMSS,
    mediaError,
    data,
}: MediaEventData) => {
    const mediaEventData = {
        eventType,
        mediaName,
        mediaType,
        mediaAkamaiPath,
        mediaActiveTimeInSec,
        mediaCurrentTimeInSec,
        mediaFullDurationInSec,
        mediaDivId,
        mediaWidth,
        mediaHeight,
        mediaCurrentTimeInHHMMSS,
        mediaFullDurationInHHMMSS,
        mediaError,
        data,
    };
    AdobeAnalyticsDataEmitter(MEDIA_PLAYER_ADOBE_ANALYTIC_EVENT_TYPE, mediaEventData);
};

export const useMetricsEmitter = (playerState: MediaPlayerState) => {
    const [intervalId, setIntervalId] = useState<number>(0);
    const [playedTime, setPlayedTime] = useState(0);
    const [metricsEmitter, setMetricsEmitted] = useState<string[]>([]);

    /**
     * Fire the metrics event with the given media event type
     * @param eventType
     */
    const fireMetricsEvent = (eventType: string, error?: any) => {
        dispatchAdobeAnalyticsMediaEvent({
            eventType,
            mediaName: playerState.mediaUrl,
            mediaType: playerState.mediaType,
            mediaAkamaiPath: playerState.mediaUrl,
            mediaActiveTimeInSec: playedTime,
            mediaCurrentTimeInSec: playerState.currentTime,
            mediaFullDurationInSec: playerState.duration,
            mediaDivId: playerState.mediaPlayerId,
            mediaWidth: 100,
            mediaHeight: 100,
            mediaCurrentTimeInHHMMSS: formatSeconds(playerState.currentTime),
            mediaFullDurationInHHMMSS: formatSeconds(playerState.duration),
            mediaError: "N/A",
            data: error || {},
        });
    };

    /**
     * Validates if the same event type has already been emitted and emits only new events
     * @param eventType
     */
    const emitSingletonEvent = (eventType: string) => {
        if (!metricsEmitter.includes(eventType)) {
            setMetricsEmitted([...metricsEmitter, eventType]);
            fireMetricsEvent(eventType);
        }
    };

    /**
     * Inspect the played time and fire the metrics event
     * Initial Real Playing event is fired when the video is played for 15 seconds
     * Real Played event is fired when the video is played for 25%, 50%, 75%, 90%
     */
    const inspectPlayedTime = () => {
        const calculatedTime = Math.round((playedTime / playerState.duration) * 100);
        if (playedTime === 15) emitSingletonEvent(PLAYING);
        switch (calculatedTime) {
            case 90:
                emitSingletonEvent(REAL_PLAYED_90_PERCENT);
                break;
            case 75:
                emitSingletonEvent(REAL_PLAYED_75_PERCENT);
                break;
            case 50:
                emitSingletonEvent(REAL_PLAYED_50_PERCENT);
                break;
            case 25:
                emitSingletonEvent(REAL_PLAYED_25_PERCENT);
                break;
            default:
                break;
        }
    };

    // Timer Metrics
    useEffect(() => {
        let interval: any;
        if (playerState.isPlaying) {
            clearInterval(intervalId);
            interval = setInterval(
                () => {
                    setPlayedTime((prevTime) => prevTime + 1);
                },
                Math.round(1000 / playerState.playbackSpeed)
            );
            inspectPlayedTime();
            setIntervalId(interval);
        } else {
            clearInterval(intervalId);
            setIntervalId(0);
        }
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playedTime, playerState.isPlaying, playerState.playbackSpeed]);

    return {
        fireMetricsEvent,
    };
};
