import React from "react";
import { ExhibitSnippetType } from "./types.ts";
import { renderExhibitPart, unescapeString } from "./utils.tsx";
import { financialList } from "./Constants.ts";

export type CompanyFinancialsProps = {
    exhibitReferencesObject: Record<string, any>;
};

const CompanyFinancials: React.FC<CompanyFinancialsProps> = ({ exhibitReferencesObject }) => (
    <>
        <div id="collapseData" className="max-w-[750px]">
            {financialList.map((financial) => {
                const exhibitReference = exhibitReferencesObject[financial];
                const exhibitTitle = exhibitReference?.exhibitTitle;
                return exhibitReference ? (
                    <div key={financial} className="mt-[10px]">
                        {exhibitTitle && (
                            <div className="text-blue-bold border-b-[1px] border-solid border-blue-bold text-[18px] font-bold">
                                {unescapeString(exhibitTitle)}
                            </div>
                        )}
                        <div className="text-[12px] leading-[22px] overflow-x-scroll">
                            {exhibitReference && renderExhibitPart(exhibitReference)}
                        </div>
                    </div>
                ) : null;
            })}
        </div>
        <div className="pt-[14px] text-[12px] leading-[14px]">
            {exhibitReferencesObject[ExhibitSnippetType.DATA_SOURCE] &&
                renderExhibitPart(exhibitReferencesObject[ExhibitSnippetType.DATA_SOURCE])}
        </div>
    </>
);

export default CompanyFinancials;
