import { ExhibitSnippetType } from "./types.ts";

export const labelViewAllFinancials = "View All Financials";

export const labelGSForecast = "GS Forecast";

export const financialList = [
    ExhibitSnippetType.RATIOS_VALUATIONS,
    ExhibitSnippetType.GROWTH_MARGINS,
    ExhibitSnippetType.ASSET_QUALITY,
    ExhibitSnippetType.CAPITAL_ITEMS,
    ExhibitSnippetType.PROFIT_MODEL,
    ExhibitSnippetType.BALANCE_SHEET,
    ExhibitSnippetType.CASH_FLOW,
    ExhibitSnippetType.PROFIT_DRIVERS,
    ExhibitSnippetType.PROFITABILITY,
];
