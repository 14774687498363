"use client";

import { useMemo, useState } from "react";
import { ContentCard } from "@forge-ui-components/atoms";
import { Tabs, Tab } from "@gs-ux-uitoolkit-react/tabs";
import { QueryComponentTitle, QueryTab } from "@forge-cms/models/components";
import { useStyleSheet } from "@gs-ux-uitoolkit-react/style";
import { QueryComponentResponse } from "@forge-ui-components/search-server-data-loader";
import { overrideStyleSheet } from "./styles.tsx";
import QueryPanelList from "./QueryPanelList.tsx";

export type QueryTabsProps = {
    tabs: QueryTab[];
    queryPanelResponse: QueryComponentResponse[];
    title?: QueryComponentTitle;
};

const QueryTabs = ({ tabs, queryPanelResponse, title }: QueryTabsProps) => {
    const [activeTab, setActiveTab] = useState<number>(0);

    const tabDataMap = useMemo(() => {
        const map = new Map<number, any>();
        queryPanelResponse?.forEach((item: any, index) => {
            map.set(index, item);
            return map;
        });
        return map;
    }, [queryPanelResponse]);

    const onTabSelect = (key: number) => {
        setActiveTab(key);
    };

    const overrideClasses = useStyleSheet(overrideStyleSheet, null);
    // Todo: rename variables
    const tabData = tabDataMap.get(activeTab);
    const activeReports = tabData?.reports || [];
    const viewMoreEnabled = tabs?.[activeTab]?.query.viewMore?.enabled;
    const viewMoreLink = tabs?.[activeTab]?.query.viewMore?.viewMoreLink || tabData?.viewMoreLink;
    const viewMoreButtonText: string = tabs?.[activeTab]?.query.viewMore?.viewMoreButtonText || "View More";

    return (
        <ContentCard
            title={title?.title}
            titleStyle={title?.titleStyle}
            footerLinkLabel={viewMoreEnabled ? viewMoreButtonText : undefined}
            footerLinkUrl={viewMoreEnabled ? viewMoreLink : undefined}>
            <div id="query-panel-container" data-testid="query-panel-container" className="px-2_5">
                <Tabs
                    variant="tabs"
                    activeTabKey={activeTab}
                    onSelect={onTabSelect}
                    size="md"
                    classes={overrideClasses}>
                    {tabs?.map((item, index: number) => (
                        <Tab tabKey={index} title={item.name.toUpperCase()} class>
                            <QueryPanelList activeReports={activeReports} queryId={item.query.id} />
                        </Tab>
                    ))}
                </Tabs>
            </div>
        </ContentCard>
    );
};

export default QueryTabs;
