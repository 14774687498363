import { ForgeIcon } from "@forge-ui-components/atoms";

const overlayIconPositionStyles = "absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]";
const overlayButtonAreaStyles = "p-[5%] rounded-[50%] border-0";
const overlayButtonColorStyles = "bg-opacity-70 bg-surface-contrast-active hover:bg-blue-subtle-active";

type PlayOverlayIconProps = {
    parentId: string;
};

const PlayOverlayIcon = ({ parentId }: PlayOverlayIconProps) => (
    <div
        id={`${parentId}-play-overlay-icon`}
        data-testid={`${parentId}-play-overlay-icon`}
        className={`${overlayIconPositionStyles} ${overlayButtonAreaStyles} ${overlayButtonColorStyles}`}>
        <ForgeIcon icon="play" pxSize={25} color="#000000" classNames="inline" />
    </div>
);

export { overlayIconPositionStyles, overlayButtonAreaStyles, overlayButtonColorStyles };
export default PlayOverlayIcon;
