import React from "react";
import Heading from "@forge-ui-components/heading";

export interface DisclosureModalProps {
    onClose: () => void;
    children: React.ReactNode;
}
const DisclosureModal: React.FC<DisclosureModalProps> = ({ onClose, children }) => (
    <div className="fixed top-0 left-0 w-full h-full flex bg-white bg-opacity-75 z-50">
        <div className="w-full h-full flex self-center justify-center items-center max-h-screen">
            <div className="flex flex-col overflow-hidden bg-white px-4 py-8 max-w-[900px] w-full max-h-screen md:max-h-[90vh] relative border border-gray-600">
                <button
                    type="button"
                    className="absolute top-0 right-0 mt-4 mr-4 pr-[4px] text-blue-600 font-extrabold cursor-pointer"
                    onClick={onClose}>
                    X
                </button>
                <div className="relative overflow-auto px-4">
                    <Heading
                        type="1"
                        text="Disclosure Appendix"
                        styleClassNames="font-sans text-[24px] leading-8 text-sky-950 font-normal border-b border-sky-950 pb-[4px] mb-[8px]"
                    />
                    {children}
                </div>
            </div>
        </div>
    </div>
);

export default DisclosureModal;
