"use client";

import { FontMultiplier, MoreMenu, PdfAction, ShareAction, BookmarkAction } from "@forge-ui-components/action-buttons";
import { ReportTemplateContext, ReportTemplateContextType } from "@forge-ui-components/context-providers";
import { setCookie } from "cookies-next";
import { useContext } from "react";
import { ReportActionBarLabels } from "./types.ts";

type ActionBarButtons = {
    pdfPath: string;
    distributionHeadline: string;
    htmlPath: string;
    baseUrl: string;
    viewMode: string;
    labels: ReportActionBarLabels;
    mediaUrl?: string;
};

const ActionBarButtons = ({
    distributionHeadline,
    htmlPath,
    pdfPath,
    baseUrl,
    viewMode,
    labels,
    mediaUrl,
}: ActionBarButtons) => {
    const { reportTemplateContextState, setReportTemplateContextState } =
        useContext<ReportTemplateContextType>(ReportTemplateContext);

    const { fontMultiplier } = reportTemplateContextState;

    const share = {
        label: labels?.shareLabel || `Share`,
        subject: `GS Research ${distributionHeadline}`,
        body: `${distributionHeadline}: ${baseUrl}${htmlPath}`,
    };

    const setFontMultiplier = (multiplier: FontMultiplier) => {
        setReportTemplateContextState({ ...reportTemplateContextState, fontMultiplier: multiplier });
        setCookie("gsPortalFontSize", multiplier, { expires: new Date(9999, 11, 31), path: "/" });
    };

    const isPublicSiteViewMode = viewMode === "public";
    const isGSNowViewMode = viewMode === "gsnow";

    return (
        <div className="flex w-full items-baseline sm:justify-end gap-x-2">
            <div className="leading-normal">
                <PdfAction href={pdfPath} label="PDF" />
            </div>
            <div>
                <ShareAction share={share} />
            </div>
            {isPublicSiteViewMode || isGSNowViewMode ? null : (
                <div>
                    <BookmarkAction label={labels.bookmarkLabel} assetPath={htmlPath} bookmarkType="report" />
                </div>
            )}
            <div>
                <MoreMenu
                    setFontMultiplier={setFontMultiplier}
                    fontMultiplier={fontMultiplier}
                    labels={labels}
                    mediaUrl={mediaUrl}
                    viewMode={viewMode}
                />
            </div>
        </div>
    );
};

export default ActionBarButtons;
