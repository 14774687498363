export const SET_IS_PLAYER_READY = "setIsPlayerReady";
export const HLS_JS_LOADED = "hlsJsLoaded";
export const SET_IS_PLAYING = "setIsPlaying";
export const SET_IS_BUFFERING = "setIsBuffering";
export const DISABLE_PREVIEW = "disablePreview";
export const SET_IS_PREVIEW_VIEWABLE = "setIsPreviewViewable";
export const SET_CURRENT_TIME = "setCurrentTime";
export const SET_SLIDER_TIME_VALUE = "setSliderTimeValue";
export const SET_DURATION = "setDuration";
export const SET_DRAGGING = "setDragging";
export const FAST_FORWARD_PROGRESS = "fastForwardProgress";
export const REWIND_PROGRESS = "rewindProgress";
export const SET_PLAYBACK_SPEED = "setPlaybackSpeed";
export const SET_VOLUME = "setVolume";
export const MUTE_VOLUME = "muteVolume";
export const UNMUTE_VOLUME = "unmuteVolume";

export const START_DRAGGING = "startDragging";
export const STOP_DRAGGING = "stopDragging";
export const HANDLE_PROGRESS = "handleProgress";
export const HANDLE_TOGGLE_PLAY = "handleTogglePlay";
export const TOGGLE_FULLSCREEN = "toggleFullscreen";
export const HANDLE_STOP = "handleStop";
