"use client";

import React, { useState, useEffect } from "react";
import { SkeletonItem, SkeletonAnimationType } from "@gs-ux-uitoolkit-react/skeleton";
import { FollowButtonProps, Interest } from "../types.ts";
import { formatForRequest } from "../utils.ts";
import { getInterestDetailsForTag } from "../requestHandler.ts";
import TagFollowButton from "../TagFollowButton/index.tsx";

const FollowButton: React.FC<FollowButtonProps> = ({ followItem, targetId, withDropdown = true, className }) => {
    const isTagOnly = "tagGuid" in followItem;
    const [isLoading, setIsLoading] = useState<boolean>(isTagOnly);

    const defaultInterest = isTagOnly
        ? {
              followActive: false,
              alertActive: false,
              distributionChannels: [],
              includeAlerts: [],
          }
        : followItem;

    const [interest, setInterest] = useState<Interest>(defaultInterest);
    const [isError, setIsError] = useState(false);
    const handleErrors = (error: boolean) => {
        setIsError(error);
        setIsLoading(false);
    };

    useEffect(() => {
        const getInterestDetails = async () => {
            const followItemData = typeof followItem === "string" ? JSON.parse(followItem) : followItem;
            const formatPayload = formatForRequest(followItemData);
            const response = await getInterestDetailsForTag([formatPayload], handleErrors);
            setInterest(response[0] || {});
            setIsLoading(false);
        };

        if (isTagOnly) {
            getInterestDetails();
        }
    }, [followItem, isTagOnly]);

    return isLoading ? (
        <SkeletonItem animation={SkeletonAnimationType.Pulse} size="md" shape="square" />
    ) : (
        <div className={className}>
            <TagFollowButton followItem={interest} targetId={targetId} withDropdown={withDropdown} isError={isError} />
        </div>
    );
};

export default FollowButton;
