export const TAG_DOMAIN_MAP = {
    authors: "AUTHORS",
    girDisciplines: "DISCIPLINES/ASSETS",
    countries: "COUNTRIES",
    currencies: "CURRENCIES",
    regions: "REGIONS",
    companies: "COMPANIES",
    securities: "SECURITIES",
    sectors: "INDUSTRIES",
    industries: "INDUSTRIES",
    girIndustries: "INDUSTRIES",
    subjects: "SUBJECTS",
    girActions: "ACTIONS",
    products: "PUBLICATIONS",
    girAssetTypes: "DISCIPLINES/ASSETS",
    productFocus: "FOCUS",
    channels: "CHANNELS",
    reportTypes: "REPORT TYPES",
    sources: "SOURCES",
    girSubSources: "SUB_SOURCES/ASSETS",
    themes: "THEMES",
    nonExistent: "NONEXISTENT",
    staticWatchList: "STATIC_WATCHLIST",
    dynamicWatchlist: "DYNAMIC_WATCHLIST",
    dualWatchlist: "DUAL_WATCHLIST",
};

export const FREQUENCIES = [
    { display: "As published", value: "As published" },
    { display: "Daily", value: "Once a day" },
    { display: "Weekly", value: "Weekly" },
];

export const LABELS = {
    follow: "FOLLOW",
    following: "FOLLOWING",
};

export const INTERESTS = {
    email: { value: "email", label: "Get Email Alerts" },
    event: { value: "event", label: "Include Events" },
    mobile: { value: "mobile", label: "Get Mobile Alerts" },
};

export const API_URL = {
    RECOMMENDED_INTERESTS: `/funl/tags/recommended-interests`,
    INTERESTS: `/funl/tags/interests`,
    QUERY: "/funl/follows/query",
};
