import { useState } from "react";
import MediaPlayer from "@forge-ui-components/media-player";
import { ForgeIcon } from "@forge-ui-components/atoms";
import classNames from "classnames";

export type ListenToReportProps = {
    label?: string;
    mediaUrl: string;
};

const ListenToReport: React.FC<ListenToReportProps> = ({
    label = "Listen to report",
    mediaUrl,
}: ListenToReportProps) => {
    const [displayMediaPlayer, setDisplayMediaPlayer] = useState(false);

    const AudioMediaPlayerProps = {
        mediaUrl,
        mediaType: "audio",
        isSlim: true,
        isCompact: true,
    };

    const toggleMediaPlayerDisplay = () => {
        setDisplayMediaPlayer(!displayMediaPlayer);
    };

    const mediaPlayerDisplayStyles = classNames({
        "w-full": true,
        hidden: !displayMediaPlayer,
    });

    return (
        <>
            <button
                data-testid="listen-to-report-button"
                className="flex items-center w-full px-3 py-1 hover:bg-gray-subtle"
                type="button"
                onClick={toggleMediaPlayerDisplay}>
                <div className="justify-self-start">
                    <ForgeIcon icon="text-to-speech" pxSize={20} color="#000000" />
                </div>
                <div className="flex-grow text-left pl-1">{label}</div>
            </button>
            <div data-testid="media-player-display-wrapper" className={mediaPlayerDisplayStyles}>
                <MediaPlayer {...AudioMediaPlayerProps} />
            </div>
        </>
    );
};
export default ListenToReport;
