/* eslint-disable react/no-danger */

"use client";

import { log } from "@forge-ui-components/tracing-client";
import { encode } from "html-entities";
import Script from "next/script";
import { ReactElement } from "react";
import { LegacyScript, webComponentMappings, webComponentScriptMappings } from "./webComponentMappings.ts";

type WebComponent = {
    type: string;
    properties: Record<string, any>;
};

const LOG_CONTEXT = { moduleName: "WebComponentMapper" };

const buildComponentHTML = (
    componentName: string,
    attrs: string[]
): {
    __html: string | TrustedHTML;
} => ({
    __html: `<${componentName} ${attrs.join(" ")} />`,
});

const getComponentScriptReference = (script: LegacyScript): ReactElement | null => {
    const scriptRef = webComponentScriptMappings[script];
    if (!scriptRef) {
        log.error(`Script reference not found for script: ${script}`, LOG_CONTEXT);
        return null;
    }

    return (
        <>
            {scriptRef.map((scriptProps) => (
                <Script {...scriptProps} />
            ))}
        </>
    );
};

/**
 * Takes a generic Web Component input (see below) and maps this to HTML representing a Web Component
 * It also ensures that any dependent scripts are included on the page, next/script will ensure only one instance
 * of the script tag appears on the page.
 *
 * Intended for backwards compatibility only. New functionality should not use this service.
 *
 * */
const WebComponent = ({ type, properties }: WebComponent) => {
    log.info(`Rendering Web Component: ${JSON.stringify(type)}`, LOG_CONTEXT);

    const componentConfig = webComponentMappings[type];

    if (!componentConfig) {
        log.error(`Component ${type} not found in webComponentMappings`, LOG_CONTEXT);
        return null;
    }

    const attrs = Object.keys(properties).map((key) => {
        const keyAlias = componentConfig.propertyMap[key];
        if (!keyAlias) {
            log.warn(`Property ${key} not found in propertyMap for ${type}`, LOG_CONTEXT);
        }
        return `data-${keyAlias || key}=${encode(
            typeof properties[key] === "object" ? JSON.stringify(properties[key]) : properties[key]
        )}`;
    });

    const componentHTML = buildComponentHTML(componentConfig.componentName, attrs);

    return (
        <>
            {getComponentScriptReference(componentConfig.scriptDependency)}
            <div dangerouslySetInnerHTML={componentHTML} />
        </>
    );
};

export { WebComponent };
export default WebComponent;
