import tracer from "@forge-ui-components/tracing-client";
import { PanamaEmitterOptions, PanamaEvent } from "./types.ts";

const LOG_CONTEXT = { moduleName: "PanamaDataEmitter" };
const PANAMA_SERVICE_PATH = "/research/services/lighthouse";

/**
 * Builds the Panama endpoint path with the default relative path or an optional override domain.
 * @param overrideDomain
 */
const buildPanamaEndpointPath = (overrideDomain?: string) => {
    if (overrideDomain) {
        return `https://${overrideDomain}${PANAMA_SERVICE_PATH}`;
    }
    return PANAMA_SERVICE_PATH;
};

/**
 * Sends a Panama event to the Panama service via a beacon request.
 * @param panamaEndpoint
 * @param panamaEvent
 */
const sendEventToPanamaViaBeaconRequest = (panamaEndpoint: string, panamaEvent: PanamaEvent) => {
    const eventBlob = new Blob([JSON.stringify(panamaEvent)], { type: "application/json" });
    navigator.sendBeacon(panamaEndpoint, eventBlob);
    tracer.log.info(`Beacon request sent to Panama with EventType: ${panamaEvent.EventType}`, LOG_CONTEXT);
};

/**
 * Sends a Panama event to the Panama service via a fetch request.
 * @param panamaEndpoint
 * @param panamaEvent
 */
const sendEventToPanamaViaFetchRequest = async (panamaEndpoint: string, panamaEvent: PanamaEvent): Promise<any> => {
    const defaultOptions = {
        headers: {
            cache: "no-store",
            Accept: "application/json",
        },
        method: "POST",
        body: JSON.stringify(panamaEvent),
    };

    const response = await fetch(panamaEndpoint, defaultOptions);
    if (response.ok) {
        tracer.log.info(`Request successfully sent to Panama with EventType=${panamaEvent.EventType}`, LOG_CONTEXT);
    } else {
        const error = `Unsuccessful response in request to Panama with EventType=${panamaEvent.EventType} body=${response.body} status=${response.status} statusText=${response.statusText}`;
        tracer.log.error(error, LOG_CONTEXT);
    }
    return response.json();
};

/**
 * Produces a Panama event with the given event type and event data
 * Send a beacon request or fetch request based on the options
 * @param eventType
 * @param eventData
 * @param options
 */
const producePanamaEvent = (eventType: string, eventData: any, options?: PanamaEmitterOptions): Promise<any> | void => {
    const panamaEvent: PanamaEvent = {
        EventType: eventType,
        EventData: eventData,
    };
    const panamaEndpointPath = buildPanamaEndpointPath(options?.overrideDomain);

    if (options?.waitForResponse) {
        return sendEventToPanamaViaFetchRequest(panamaEndpointPath, panamaEvent);
    }
    sendEventToPanamaViaBeaconRequest(panamaEndpointPath, panamaEvent);
};

export default producePanamaEvent;
export { producePanamaEvent };
