import { formatSeconds } from "../utils/format.ts";

export type PlayTimeDisplayProps = {
    currentTime: number;
    duration: number;
};

const PlayTimeDisplay = ({ currentTime, duration }: PlayTimeDisplayProps) => (
    <div
        id="media-player-time-display"
        data-testid="media-player-time-display"
        className="font-bold ml-1 mt-0_25 whitespace-nowrap text-xs">
        {`${formatSeconds(currentTime)}/${formatSeconds(duration)}`}
    </div>
);

export default PlayTimeDisplay;
