import { ForgeIcon } from "@forge-ui-components/atoms";

export type FullScreenControlsProps = {
    isFullScreen: boolean;
    isCompact: boolean;
    onFullScreenButtonClick: Function;
};

const FullscreenControls = ({ onFullScreenButtonClick, isCompact, isFullScreen }: FullScreenControlsProps) => {
    const handleChange = () => {
        onFullScreenButtonClick();
    };
    return isCompact ? null : (
        <div
            id="media-player-fullscreen-control-container"
            data-testid="media-player-fullscreen-control-container"
            className="ml-0_5">
            <button
                id="media-player-fullscreen-control-button"
                data-testid="media-player-fullscreen-control-button"
                type="button"
                aria-label="media-player-fullscreen-control"
                onClick={() => handleChange()}>
                {isFullScreen ? (
                    <ForgeIcon icon="collapse" pxSize={17} color="#FFFFFF" classNames="inline align-middle" />
                ) : (
                    <ForgeIcon icon="expand" pxSize={17} color="#FFFFFF" classNames="inline align-middle" />
                )}
            </button>
        </div>
    );
};

export default FullscreenControls;
