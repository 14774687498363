"use client";

import dynamic from "next/dynamic";

/**
 * Wrapper file from client components dynamically imported for PTE use - required due to this limitation:
 * https://github.com/vercel/next.js/issues/61066
 */

const DynamicMediaPlayer = dynamic(() => import("@forge-ui-components/media-player").then((mod) => mod.default), {
    ssr: false,
});

const clientComponents: Record<string, any> = {
    DynamicMediaPlayer,
};

const ClientWrapper = ({ component, ...props }: any) => {
    const ClientComponent = clientComponents[component];
    return <ClientComponent {...props} />;
};

export default ClientWrapper;
