"use client";

import { ReportTemplateContext } from "@forge-ui-components/context-providers";
import { useContext } from "react";

type ExhibitDisplayProps = {
    className?: string;
    alt: string;
    assetUrl: string;
    uniqueExhibitId: string;
    isExcludedFromChartGallery: boolean;
};

const ExhibitDisplay = ({
    className,
    alt,
    assetUrl,
    uniqueExhibitId,
    isExcludedFromChartGallery,
}: ExhibitDisplayProps) => {
    const { reportTemplateContextState, setReportTemplateContextState } = useContext(ReportTemplateContext);
    const showGallery = (event: React.UIEvent<HTMLElement>) => {
        if (reportTemplateContextState) {
            setReportTemplateContextState({
                ...reportTemplateContextState,
                galleryOpen: true,
                galleryZoomLevel: 1,
                currentExhibitId: uniqueExhibitId,
            });
        }
        event.preventDefault();
    };

    return isExcludedFromChartGallery ? (
        <img className={className} src={assetUrl} alt={alt} />
    ) : (
        <img className={className} src={assetUrl} alt={alt} onClick={showGallery} onKeyDown={showGallery} />
    );
};

export type { ExhibitDisplayProps };
export default ExhibitDisplay;
