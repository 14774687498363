import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export type TimezoneInterface = {
    firstTimeZoneFormat: string;
    secondTimeZoneFormat: string;
    thirdTimeZoneFormat: string;
    firstTz: string;
    secondTz: string;
    thirdTz: string;
};

export type DateFormatInterface = {
    firstDate: any;
    secondDate: any;
    thirdDate: any;
    secondAdd: string;
    thirdAdd: string;
    allTimeZone: string;
};

enum Timezone {
    EST = "EST",
    HKT = "HKT",
    GMT = "GMT",
}

// To Do: Refactor this class component into a functional components.
export class DateUtils {
    static getFormattedTime = (timeVal: string) => {
        const format = /:00/g;
        return timeVal.replace(format, "");
    };

    /**
     * formats time based on the timezone
     * @param tz - the timezone string.
     * @returns - timezone formats.
     */
    static getTimeZoneFormats = (tz: Timezone): TimezoneInterface => {
        switch (tz) {
            case Timezone.EST:
                return {
                    firstTimeZoneFormat: "America/New_York",
                    secondTimeZoneFormat: "Europe/London",
                    thirdTimeZoneFormat: "Asia/Hong_Kong",
                    firstTz: "NY",
                    secondTz: "LN",
                    thirdTz: "HK",
                };
            case Timezone.HKT:
                return {
                    firstTimeZoneFormat: "Asia/Hong_Kong",
                    secondTimeZoneFormat: "America/New_York",
                    thirdTimeZoneFormat: "Europe/London",
                    firstTz: "HK",
                    secondTz: "NY",
                    thirdTz: "LN",
                };
            case Timezone.GMT:
                return {
                    firstTimeZoneFormat: "Europe/London",
                    secondTimeZoneFormat: "America/New_York",
                    thirdTimeZoneFormat: "Asia/Hong_Kong",
                    firstTz: "LN",
                    secondTz: "NY",
                    thirdTz: "HK",
                };
            default:
                return {
                    firstTimeZoneFormat: "UTC",
                    secondTimeZoneFormat: "UTC",
                    thirdTimeZoneFormat: "UTC",
                    firstTz: "UTC",
                    secondTz: "UTC",
                    thirdTz: "UTC",
                };
        }
    };

    /**
     * Formats the webcast date time based on multiple timezones.
     * @param report - the report object containing start date, event.timezone and other details.
     * @returns formatted webcast date time string including multiple timezones.
     */
    static getWebCastDateTime = (report: any) => {
        const timeFormat = report.event.timeZone.split(",");
        const timeZone = timeFormat.length > 1 ? timeFormat[1] : timeFormat[0];
        const timeZoneFormat = DateUtils.getTimeZoneFormats(timeZone);
        const dateFormat: DateFormatInterface = {
            firstDate: "",
            secondDate: "",
            thirdDate: "",
            secondAdd: "",
            thirdAdd: "",
            allTimeZone: "",
        };
        dateFormat.firstDate = dayjs.tz(report.event.startDate, timeZoneFormat.firstTimeZoneFormat);
        dateFormat.secondDate = dateFormat.firstDate.clone().tz(timeZoneFormat.secondTimeZoneFormat);
        dateFormat.thirdDate = dateFormat.firstDate.clone().tz(timeZoneFormat.thirdTimeZoneFormat);
        dateFormat.secondAdd = this.getComparisonDate(dateFormat.secondDate, dateFormat.firstDate);
        dateFormat.thirdAdd = this.getComparisonDate(dateFormat.thirdDate, dateFormat.firstDate);

        dateFormat.allTimeZone = `${
            dateFormat.firstDate.format("D MMM YYYY | h:mma ") + timeZoneFormat.firstTz
        } | ${dateFormat.secondDate.format("h:mma ")}${timeZoneFormat.secondTz}${
            dateFormat.secondAdd
        } | ${dateFormat.thirdDate.format("h:mma ")}${timeZoneFormat.thirdTz}${dateFormat.thirdAdd}`;

        return DateUtils.getFormattedTime(dateFormat.allTimeZone);
    };

    /**
     * Compares two dates to define if they're on different days and returns a label if they are.
     * @param secondDate - the date to compare (usually in a different timezone)
     * @param firstDate - the original date to compare against (usually in a different timezone)
     * @returns - label indicating if the dates are on different days.
     */
    static getComparisonDate = (secondDate: dayjs.Dayjs, firstDate: dayjs.Dayjs) => {
        if (!secondDate || !firstDate) {
            return "";
        }
        if (
            secondDate.get("month") > firstDate.get("month") ||
            (secondDate.get("month") === firstDate.get("month") && secondDate.get("date") > firstDate.get("date"))
        ) {
            return "(+1)";
        }
        if (
            secondDate.get("month") < firstDate.get("month") ||
            (secondDate.get("month") === firstDate.get("month") && secondDate.get("date") < firstDate.get("date"))
        ) {
            return "(-1)";
        }
        return "";
    };
}
