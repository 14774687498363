import { Button } from "@gs-ux-uitoolkit-react/button";
import classNames from "classnames";
import { ForgeIcon } from "../icons/ForgeIcon.tsx";

type ContentCardTitleProps = {
    title?: string;
    titleStyle?: "grey" | "white" | "blue";
    actionLink?: string;
    actionLabel?: string;
};

export type ContentCardFooterProps = {
    footerLinkLabel?: string;
    footerLinkUrl?: string;
    footerLinkTarget?: "_blank" | "_self" | "_parent" | "_top";
    sendMetrics?: () => void;
};

export type ContentCardProps = {
    title?: string;
    titleStyle?: "grey" | "white" | "blue";
    actionLink?: string;
    actionLabel?: string;
    footerLinkLabel?: string;
    footerLinkUrl?: string;
    footerLinkTarget?: "_blank" | "_self" | "_parent" | "_top";
    children: React.ReactNode;
    sendMetrics?: () => void;
};

const regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
const regexForHTMLTag = /<[^>]*>/g;

const ContentCardHeader = ({ title, titleStyle, actionLink, actionLabel }: ContentCardTitleProps) => {
    if (!title) {
        return null;
    }

    const showActionButton = actionLink && actionLink.length > 0 && actionLabel && actionLabel.length > 0;

    const styles = classNames({
        "flex justify-between border-b border-gray-subtle items-center py-1 px-2_5": true,
        "bg-gray-subtle": titleStyle === "grey",
        "bg-[#0d4ea670] text-white": titleStyle === "blue",
    });

    const titleElement = regexForHTML.test(title) ? title.replace(regexForHTMLTag, "") : title;

    return (
        <div data-testid="content-card-header" className={styles}>
            <span data-testid="content-card-title" className="font-bold text-base uppercase align-middle">
                {titleElement}
            </span>
            {showActionButton && (
                <span data-testid="content-card-action-button" className="align-middle">
                    <a href={actionLink}>
                        <Button
                            actionType={titleStyle === "blue" ? "primary" : "secondary"}
                            emphasis="subtle"
                            style={{ backgroundColor: "#fff" }}>
                            {actionLabel}
                        </Button>
                    </a>
                </span>
            )}
        </div>
    );
};

export const ContentCardFooter = ({
    footerLinkLabel = "View More",
    footerLinkUrl,
    footerLinkTarget = "_self",
    sendMetrics,
}: ContentCardFooterProps) => {
    const footerEnabled = footerLinkUrl && footerLinkUrl.length > 0;

    if (!footerEnabled) {
        return null;
    }

    return (
        <div
            data-testid="content-card-footer"
            className="py-1 px-1_5 text-right font-bold border-t border-surface-gray-subtle">
            <a
                className="text-center text-text-link text-sm hover:underline"
                href={footerLinkUrl}
                target={footerLinkTarget}
                onClick={sendMetrics}>
                {footerLinkLabel}
                <ForgeIcon icon="chevron-right" pxSize={20} color="#186ade" classNames="inline" />
            </a>
        </div>
    );
};

const ContentCard = ({
    title,
    titleStyle,
    footerLinkLabel,
    footerLinkUrl,
    actionLabel,
    actionLink,
    footerLinkTarget = "_self",
    children,
    sendMetrics,
}: ContentCardProps) => (
    <div
        data-testid="content-card-container"
        className="flex flex-col h-full bg-white border border-gray-subtle rounded mb-1">
        <ContentCardHeader title={title} titleStyle={titleStyle} actionLink={actionLink} actionLabel={actionLabel} />
        {children}
        <ContentCardFooter
            footerLinkLabel={footerLinkLabel}
            footerLinkUrl={footerLinkUrl}
            footerLinkTarget={footerLinkTarget}
            sendMetrics={sendMetrics}
        />
    </div>
);

export default ContentCard;
