import type {
    ReportTemplateContextType,
    ReportTemplateContextProviderProps,
    FontMultiplier,
} from "./ReportContext.tsx";
import { ReportTemplateContext, ReportTemplateContextProvider } from "./ReportContext.tsx";
import { ContactProfileContext, ContactProfileContextProvider } from "./ContactProfileContextProvider.tsx";
import { BookmarkGlossaryContext, BookmarkGlossaryContextProvider } from "./BookmarkGlossaryContextProvider.tsx";
import { ReportBody } from "./ReportBody.tsx";
import type { ReportBodyProps } from "./ReportBody.tsx";
import type { ContactProfileContextType } from "./ContactProfileContextProvider.tsx";
import type { BookmarkGlossaryContextType } from "./BookmarkGlossaryContextProvider.tsx";

export type {
    ReportTemplateContextType,
    ReportTemplateContextProviderProps,
    FontMultiplier,
    ReportBodyProps,
    ContactProfileContextType,
    BookmarkGlossaryContextType,
};
export {
    ReportTemplateContext,
    ReportTemplateContextProvider,
    ReportBody,
    ContactProfileContext,
    ContactProfileContextProvider,
    BookmarkGlossaryContext,
    BookmarkGlossaryContextProvider,
};
export default {
    ReportTemplateContext,
    ReportTemplateContextProvider,
};
