import { useState, useEffect, useMemo } from "react";
import { Interest, FollowHooks } from "../types.ts";
import { isEmpty, transformTag } from "../utils.ts";
import { putInterest } from "../requestHandler.ts";
import { API_URL } from "../constants.ts";

export const useCustomInterestFollow = (
    followItem: Interest,
    withDropdown: boolean,
    shouldExecute: boolean
): FollowHooks | null => {
    const [interest, setInterest] = useState<Interest>({
        followActive: false,
        alertActive: false,
        distributionChannels: [],
        includeAlerts: [],
    });

    const [isError, setIsError] = useState(false);

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [isFollowLoading, setIsFollowLoading] = useState(false);
    const [showBellIcon, setShowBellIcon] = useState(false);

    const handleErrors = (error: boolean) => {
        setIsError(error);
    };

    const saveInterestData = (interests: Interest[] | Interest) => {
        const apiUrl = API_URL.INTERESTS;
        return putInterest(apiUrl, interests, handleErrors);
    };

    const createInterest = async () => {
        const interestObj: Interest = {
            ...interest,
            followActive: true,
            alertActive: true,
            frequency: followItem.frequency ?? "As published",
            distributionChannels: ["email"],
            includeAlerts: ["event"],
        };

        delete interestObj.interestId;

        const response = await saveInterestData([interestObj]);
        setInterest({ ...response[0] });
        setIsFollowLoading(false);
    };

    const deleteInterest = async () => {
        const interestObj = {
            ...interest,
            followActive: false,
            alertActive: false,
            distributionChannels: [],
            includeAlerts: [],
        };

        const response = await saveInterestData([interestObj]);
        setInterest({
            ...interestObj,
            followActive: response[0].followActive,
            includeAlerts: response[0].includeAlerts,
            distributionChannels: response[0].distributionChannels,
        });
        setIsFollowLoading(false);
    };

    const handleFollowButtonClick = async () => {
        // If the interest is not followed, create the interest
        if (!interest?.followActive) {
            setIsFollowLoading(true);
            await createInterest();
        } else if (!withDropdown) {
            // If the interest is followed and the dropdown is not allowed, delete the interest
            setIsFollowLoading(true);
            await deleteInterest();
        }
        // If the interest is followed and the dropdown is allowed, toggle the dropdown visibility
        if (withDropdown && !isFollowLoading) {
            setDropdownVisible(!dropdownVisible);
        }
    };

    // ------------------------------------------- Methods to update checkboxes --------------------------------------------------

    const updateIncludeAlerts = async (includeAlerts: string[]) => {
        const response = await saveInterestData([{ ...interest, includeAlerts }]);
        setInterest({ ...response[0] });
    };

    const updateDistributionFrequency = async (frequency: string) => {
        const response = await saveInterestData([{ ...interest, frequency }]);
        setInterest({ ...response[0] });
    };

    const toggleEmailAlertDistributions = async () => {
        let distributionChannelsList = interest?.distributionChannels || [];
        let includeAlerts = interest?.includeAlerts || [];
        const enableAlert = !distributionChannelsList.includes("email");
        if (enableAlert) {
            distributionChannelsList.push("email");
            if (!includeAlerts.includes("event")) {
                includeAlerts.push("event");
            }
        } else {
            distributionChannelsList = distributionChannelsList.filter((c: string) => c !== "email");
            includeAlerts = includeAlerts.filter((v) => v !== "event");
        }

        const alertActive = !isEmpty(distributionChannelsList);
        let interestObj = {};

        interestObj = {
            ...interest,
            alertActive,
            includeAlerts,
            distributionChannels: distributionChannelsList,
        };

        const response = await saveInterestData([interestObj]);
        setInterest({ ...response[0] });
    };

    const toggleMobileAlertDistributions = async () => {
        let distributionChannelsList = interest?.distributionChannels || [];
        const enableAlert = !distributionChannelsList.includes("mobile");

        if (enableAlert) {
            distributionChannelsList.push("mobile");
        } else {
            distributionChannelsList = distributionChannelsList.filter((c: string) => c !== "mobile");
        }

        const alertActive = !isEmpty(distributionChannelsList);
        let interestObj = {};

        interestObj = {
            ...interest,
            distributionChannels: distributionChannelsList,
            alertActive,
        };

        const response = await saveInterestData([interestObj]);
        setInterest({ ...response[0] });
    };

    const toggleIncludeAlerts = () => {
        let includeAlerts = interest?.includeAlerts || [];
        if (includeAlerts.includes("event")) {
            includeAlerts = includeAlerts.filter((v) => v !== "event");
        } else {
            includeAlerts.push("event");
        }
        updateIncludeAlerts(includeAlerts);
    };

    const toggleAlerts = (type: string) => {
        switch (type) {
            case "email":
                toggleEmailAlertDistributions();
                break;
            case "mobile":
                toggleMobileAlertDistributions();
                break;
            case "event":
                toggleIncludeAlerts();
                break;
            default:
                break;
        }
    };
    //---------------------------------------------------------------------------------------------

    const handleUnFollowButtonClick = async () => {
        await deleteInterest();
        setDropdownVisible(false);
    };

    const hideMenu = () => {
        setDropdownVisible(false);
    };

    const distributionChannels = useMemo(() => interest?.distributionChannels || [], [interest]);

    useEffect(() => {
        const getInterestDetails = async () => {
            if (followItem) {
                const transformedFollowItem = transformTag(followItem);
                setInterest(transformedFollowItem);
            }
        };
        getInterestDetails();
    }, [followItem]);

    useEffect(() => {
        setShowBellIcon(!isEmpty(interest?.distributionChannels || []));
    }, [interest]);

    if (!shouldExecute) return null;

    return {
        isError,
        interest,
        isFollowLoading,
        showBellIcon,
        dropdownVisible,
        setDropdownVisible,
        hideMenu,
        distributionChannels,
        onFrequencyChange: updateDistributionFrequency,
        handleFollow: handleFollowButtonClick,
        handleUnFollow: handleUnFollowButtonClick,
        toggleAlerts,
    };
};
