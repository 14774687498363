import Thumbnail from "./Thumbnail.tsx";
import PlayOverlayIcon from "./common/PlayOverlayIcon.tsx";
import { DISABLE_PREVIEW, HANDLE_TOGGLE_PLAY } from "./actions.ts";

export type PreviewProps = {
    isReady: boolean;
    displayPreview: boolean;
    mediaThumbnailUrl?: string;
    fireMetricsEvent: Function;
    dispatch: Function;
};

const Preview = ({ isReady, displayPreview, mediaThumbnailUrl, fireMetricsEvent, dispatch }: PreviewProps) =>
    displayPreview ? (
        <div
            id="media-player-preview"
            data-testid="media-player-preview"
            className="absolute w-full h-full top-0 left-0 z-10"
            role="presentation"
            onClick={() => {
                dispatch({ type: DISABLE_PREVIEW });
                dispatch({ type: HANDLE_TOGGLE_PLAY, emitter: fireMetricsEvent });
            }}>
            {mediaThumbnailUrl ? <Thumbnail thumbnailSource={mediaThumbnailUrl} /> : null}
            {isReady ? <PlayOverlayIcon parentId="media-player-preview" /> : null}
        </div>
    ) : null;

export default Preview;
