import { FC } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalPlacement } from "@gs-ux-uitoolkit-react/modal";
import { Button } from "@gs-ux-uitoolkit-react/button";
import { Link, Text } from "@gs-ux-uitoolkit-react/text";
import FollowButton from "@forge-ui-components/follow-button";
import { followItemClasses, modalFooterClasses, modalSectionClasses } from "./styles.ts";
import { Tag } from "../types.ts";

interface ReportFollowModalProps {
    placement: ModalPlacement;
    visible: boolean;
    toggleVisibility: () => void;
    tags: Tag[];
}

const ReportFollowModal: FC<ReportFollowModalProps> = ({ placement, visible, toggleVisibility, tags }) => (
    <Modal visible={visible} onVisibilityToggle={toggleVisibility} placement={placement}>
        <ModalHeader className={modalSectionClasses} onDismissButtonClick={toggleVisibility}>
            Follow Tags
        </ModalHeader>
        <ModalBody className={modalSectionClasses}>
            <div>
                {tags.map((tag, index) => (
                    <div key={tag.tagGuid} className={followItemClasses} data-testid="follow-button-row">
                        <div className="pr-1">{tag.tagName}</div>
                        <div>
                            {/* targetId used in UITK menu can not begin with a number */}
                            <FollowButton followItem={tag} withDropdown targetId={`target_${tag.tagGuid}_${index}`} />
                        </div>
                    </div>
                ))}
            </div>
        </ModalBody>
        <ModalFooter className={`${modalFooterClasses} ${modalSectionClasses}`}>
            <Link href="/content/site/preferences.html#/alerts" data-testid="follow-button-link">
                <Text>Manage Follows & Alerts</Text>
            </Link>
            <Button actionType="secondary" emphasis="subtle" onClick={toggleVisibility}>
                Done
            </Button>
        </ModalFooter>
    </Modal>
);

export default ReportFollowModal;
