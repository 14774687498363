type ThumbnailProps = {
    thumbnailSource: string;
    altText?: string;
};

const baseAltText = "Audio thumbnail ";
const thumbnailClasses = "aspect-[16/9] mb_5";
const imageClasses = "absolute top-0 left-0 w-full h-full object-cover";

// Todo: Create Image Atom
const Thumbnail = ({ thumbnailSource, altText }: ThumbnailProps) => (
    <div data-testid="media-player-thumbnail" className={thumbnailClasses}>
        <img
            data-testid="media-player-thumbnail-image"
            src={thumbnailSource}
            className={imageClasses}
            alt={`${baseAltText}${altText || ""}`}
        />
    </div>
);

export default Thumbnail;
