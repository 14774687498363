import { ForgeIcon } from "@forge-ui-components/atoms";
import { Menu, MenuOption } from "@gs-ux-uitoolkit-react/menu";
import React, { useState } from "react";

const menuOptions = {
    speed: {
        displayValue: "speed",
        settings: [
            {
                value: 0.25,
                displayValue: "0.25x",
            },
            {
                value: 0.5,
                displayValue: "0.5x",
            },
            {
                value: 0.75,
                displayValue: "0.75x",
            },
            {
                value: 1,
                displayValue: "Normal",
            },
            {
                value: 1.25,
                displayValue: "1.25x",
            },
            {
                value: 1.5,
                displayValue: "1.5x",
            },
            {
                value: 2,
                displayValue: "2x",
            },
        ],
    },
};

export type SettingsControlsProps = {
    mediaPlayerId: string;
    playbackSpeed: number;
    dispatch: React.Dispatch<any>;
};

const SettingsControls = ({ mediaPlayerId, playbackSpeed, dispatch }: SettingsControlsProps) => {
    const [visible, setVisible] = useState(false);

    const toggleMenu = () => {
        setVisible(!visible);
    };

    const handleChange = (value: any) => {
        dispatch({ type: "setPlaybackSpeed", payload: value });
    };

    return (
        <div data-testid="media-player-settings-container">
            <button
                key={`media-player-settings-button-${mediaPlayerId}`}
                id={`media-player-settings-button-${mediaPlayerId}`}
                data-testid={`media-player-settings-button-${mediaPlayerId}`}
                onClick={toggleMenu}
                type="button"
                aria-label="media-player-settings-button">
                <ForgeIcon icon="settings" pxSize={17} color="#FFFFFF" classNames="inline align-middle" />
            </button>
            <Menu
                key={`media-player-settings-menu-${mediaPlayerId}`}
                id={`media-player-settings-menu-${mediaPlayerId}`}
                data-testid={`media-player-settings-menu-${mediaPlayerId}`}
                classes={{ root: `min-w-8` }}
                visible={visible}
                value={playbackSpeed}
                target={`#media-player-settings-button-${mediaPlayerId}`}
                placement="top"
                onBlur={() => setVisible(false)}>
                {Object.values(menuOptions).map((config) => (
                    <MenuOption key={`media-player-settings-menu-playbackSpeed-${mediaPlayerId}`}>
                        {config.displayValue} {playbackSpeed}
                        <Menu
                            key={`media-player-settings-speed-menu-${mediaPlayerId}`}
                            classes={{ root: `min-w-8` }}
                            placement="left"
                            onChange={handleChange}>
                            {config.settings.map((setting) => (
                                <MenuOption
                                    key={`media-player-settings-option-${mediaPlayerId}-${setting.value}`}
                                    value={setting.value}>
                                    {setting.displayValue}
                                </MenuOption>
                            ))}
                        </Menu>
                    </MenuOption>
                ))}
            </Menu>
        </div>
    );
};

export default SettingsControls;
