import classNames from "classnames";
import React from "react";
import { ForgeIcon } from "@forge-ui-components/atoms";
import { decode } from "html-entities";

const handleItemClick = (mouseEvent: any, setIsExpanded: Function) => {
    mouseEvent.preventDefault();
    const chapterReference = mouseEvent.target.getAttribute("href");
    setIsExpanded(false);
    const chapterDiv = document.getElementById(chapterReference.replace("#", ""));
    if (chapterDiv) {
        chapterDiv.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }
};

export type ChapterNavigationDetails = {
    id: string;
    title: string;
};

export type TableOfContentsProps = {
    chapterNavigationData: ChapterNavigationDetails[];
    setIsExpanded: Function;
    isExpanded: boolean;
    tocLabel: string;
};

const TableOfContents: React.FC<TableOfContentsProps> = ({
    chapterNavigationData,
    setIsExpanded,
    isExpanded,
    tocLabel = "Table of Contents",
}: TableOfContentsProps) => {
    const contentsClass = classNames({
        "md:px-[4.75rem] bg-gray-subtle-background w-full": true,
    });
    const parentClass = classNames({
        "w-full bg-gray-subtle-background": true,
        "max-h-[calc(100vh-200px)] absolute": isExpanded,
        "max-h-0 overflow-hidden absolute": !isExpanded,
    });
    const actionMenuClass = classNames({
        "col-span-16 col-start-1 sm:col-span-4 sm:col-start-13 items-center sm:flex px-4 sm:justify-end": true,
    });

    return (
        <div
            className={parentClass}
            style={{
                transition: "max-height 0.5s ease-out",
                zIndex: "100",
                overflowY: "auto",
            }}>
            <div className="grid grid-cols-16 col-span-16 col-start-0 py-2_5 bg-gray-subtle-background z-10 top-0 sticky">
                <div className="col-span-16 col-start-1 sm:col-span-12 sm:col-start-1 px-4 lg:pl-[5.75rem]">
                    <div>
                        <div data-testid="contents-title-bar" className="font-sans font-normal flex items-center">
                            {chapterNavigationData.length > 1 && (
                                <button
                                    type="button"
                                    onClick={() => setIsExpanded(!isExpanded)}
                                    aria-label={isExpanded ? "Close Table of Contents" : "Open Table of Contents"}
                                    className="bg-black text-white rounded-full p-0_25 cursor-pointer mr-2">
                                    <ForgeIcon icon="list" pxSize={20} color="#ffff" />
                                </button>
                            )}
                            <span data-testid="contents-title">{tocLabel}</span>
                        </div>
                    </div>
                </div>
                <div className={actionMenuClass}>
                    <div className="hidden sm:block sm:float-right">
                        <button
                            type="button"
                            onClick={() => setIsExpanded(!isExpanded)}
                            className="ml-auto pr-6"
                            data-testid="contents-close">
                            &#x2715;
                        </button>
                    </div>
                </div>
            </div>
            <div data-testid="TOC" className={contentsClass}>
                <ul data-testid="TOCList" className="m-0 divide-y divide-black">
                    {chapterNavigationData.map((chapterNavigation) => (
                        <li className="relative" key={chapterNavigation.id}>
                            <a
                                className="font-sans text-sm text-black font-medium block py-2 px-4"
                                href={`#${chapterNavigation.id}`}
                                onClick={(e) => handleItemClick(e, setIsExpanded)}>
                                {decode(chapterNavigation.title)}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default TableOfContents;
