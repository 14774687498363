"use client";

import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Text } from "@forge-ui-components/atoms";
import DisclosureModal from "./DisclosureModal.tsx";

export type DisclosureProps = {
    text: string;
    children: React.ReactNode;
};

const base64DecodeAndParse = (html: string) => ReactHtmlParser(Buffer.from(html, "base64").toString("utf-8"));

const Disclosure: React.FC<DisclosureProps> = ({ text, children }) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const anchorClassName = "font-sans font-light text-text-link visited:text-text-link hover:underline cursor-pointer";

    function hideModal() {
        setShowModal(false);
    }

    const handleAnchorClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        const { target } = e;
        const anchorTarget = target as HTMLAnchorElement;
        if (anchorTarget.tagName === "A" && anchorTarget.getAttribute("href") === "#inline_content") {
            e.preventDefault();
            setShowModal(true);
        }
    };

    const addClickHandlersToAnchor = (childrens: React.ReactNode): React.ReactNode =>
        // eslint-disable-next-line consistent-return
        React.Children.map(childrens, (child) => {
            if (!React.isValidElement(child)) {
                return child;
            }
            if (child.type === "a") {
                return React.cloneElement(child, {
                    ...child.props,
                    class: anchorClassName,
                    className: anchorClassName,
                    onClick: handleAnchorClick,
                });
            }

            if (child.props.children) {
                return React.cloneElement(child, {
                    ...child.props,
                    children: addClickHandlersToAnchor(child.props.children),
                });
            }
        });
    const textChildren = addClickHandlersToAnchor(base64DecodeAndParse(text));
    return (
        <section>
            <div className="border-t border=[#D8D8D8] mb-5" />
            <Text className="mb-5" typography="report01">
                {textChildren}
                {showModal && (
                    // eslint-disable-next-line react/jsx-no-bind
                    <DisclosureModal onClose={hideModal}>{children}</DisclosureModal>
                )}
            </Text>
            <div className="mb-5" />
        </section>
    );
};

export default Disclosure;
