import { SearchItem } from "@forge-ui-components/atoms";
import { Report } from "@forge-ui-components/search-server-data-loader";

type Reportsprops = {
    activeReports: Report[];
    queryId: string;
};

const QueryPanelList = ({ activeReports, queryId }: Reportsprops) => (
    <div>
        {activeReports?.map((report: any, tabIndex: number) => (
            <div
                id={`query-panel-item-container-${queryId}`}
                data-testid="query-panel-item-container"
                className="flex text-sm font-semibold">
                <div data-testid="query-panel-item-content" className="py-1 grow">
                    <SearchItem
                        key={report.id}
                        distributionHeadline={report.distributionHeadline}
                        path={report.path}
                        totalPages={report.totalPages}
                        leadAuthor={report.authors[0].name}
                        hasMultipleAuthors={report.authors.length > 1}
                        sourceDisplayName={report.sourceDisplayName}
                        publicationDateTime={report.publicationDateTime}
                        paddingStyleOverrides="px-0"
                    />
                    {tabIndex !== activeReports.length - 1 ? (
                        <div className="border-b border-neutral-200 py-1" />
                    ) : null}
                </div>
            </div>
        ))}
    </div>
);

export default QueryPanelList;
