export type ExhibitReference = {
    companyGuid?: string;
    isExcludedFromChartGallery: string;
    data: string;
    imageUrl?: string;
    type: string;
    exhibitTitle?: string;
    source?: string;
    rating?: string;
    showConvictionList?: string;
};

export type ExhibitDataItem = {
    keyword: string;
    value: string;
};

export enum ExhibitSnippetType {
    PRICE_STRIP = "priceStripExhibit",
    DATA_SNIPPET = "dataSnippetExhibit",
    KEY_DATA = "keyDataExhibit",
    GS_FORECAST = "gsForecastExhibit",
    DATA_SOURCE = "dataSnippetSource",
    RATIOS_VALUATIONS = "ratiosAndValuationsExhibit",
    GROWTH_MARGINS = "growthMarginsExhibit",
    ASSET_QUALITY = "assetQualityExhibit",
    CAPITAL_ITEMS = "capitalItemsExhibit",
    PROFIT_MODEL = "profitModelExhibit",
    BALANCE_SHEET = "balanceSheetExhibit",
    CASH_FLOW = "cashFlowExhibit",
    PROFIT_DRIVERS = "profitDriversExhibit",
    PROFITABILITY = "profitabilityExhibit",
}

export type Language = "en" | "zh" | "ja";

export type RatingType = {
    matchers: string[];
    displayText: Record<Language, string>;
    bgStyle: string;
    textStyle: Record<Language, string>;
    clTextStyle?: string; // Not provided means CL should never be displayed for this rating type
};
