"use client";

import { useReducer } from "react";
import { v4 as uuidv4 } from "uuid";
import ReactPlayer from "react-player";
import classNames from "classnames";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import type { MediaPlayerProps, MediaPlayerState } from "./types.ts";

import { formatSeconds } from "./utils/format.ts";
import ControlBar from "./controlBar/ControlBar.tsx";
import Thumbnail from "./Thumbnail.tsx";
import Preview from "./Preview.tsx";
import { useMetricsEmitter } from "./hooks/metricsHook.ts";

import {
    HANDLE_PROGRESS,
    HANDLE_TOGGLE_PLAY,
    SET_DURATION,
    SET_VOLUME,
    SET_PLAYBACK_SPEED,
    SET_IS_BUFFERING,
    SET_IS_PLAYER_READY,
    MUTE_VOLUME,
    UNMUTE_VOLUME,
    FAST_FORWARD_PROGRESS,
    REWIND_PROGRESS,
    START_DRAGGING,
    STOP_DRAGGING,
    DISABLE_PREVIEW,
    TOGGLE_FULLSCREEN,
    HLS_JS_LOADED,
    HANDLE_STOP,
} from "./actions.ts";
import {
    AUDIO_MEDIA_TYPE,
    FAST_PROGRESS_INCREMENT,
    MAX_SLIDER_VALUE,
    mediaPlayerConfigs,
    MIN_SLIDER_VALUE,
    SLIDER_INCREMENT_VALUE,
    VIDEO_MEDIA_TYPE,
    PLAY,
    PAUSE,
    ENDED,
    ERROR,
} from "./constants.ts";

const reducer = (state: MediaPlayerState, action: any) => {
    const nextState = { ...state };
    switch (action.type) {
        case HLS_JS_LOADED:
            nextState.isHLSJSLoaded = true;
            return nextState;
        case SET_IS_PLAYER_READY:
            nextState.playerReference = action.payload;
            nextState.isPlayerReady = true;
            return nextState;
        case DISABLE_PREVIEW:
            nextState.displayPreview = false;
            return nextState;
        case SET_IS_BUFFERING:
            nextState.isBuffering = action.payload;
            return nextState;
        case SET_DURATION:
            nextState.duration = action.payload;
            return nextState;
        case SET_VOLUME:
            nextState.volume = action.payload;
            return nextState;
        case MUTE_VOLUME:
            nextState.isMuted = true;
            nextState.previousVolume = nextState.volume;
            nextState.volume = 0;
            return nextState;
        case UNMUTE_VOLUME:
            nextState.isMuted = false;
            nextState.volume = nextState.previousVolume;
            return nextState;
        case SET_PLAYBACK_SPEED:
            nextState.playbackSpeed = action.payload;
            return nextState;
        case FAST_FORWARD_PROGRESS:
            if (state.playerReference)
                state.playerReference.seekTo(
                    state.playerReference.getCurrentTime() + FAST_PROGRESS_INCREMENT,
                    "seconds"
                );
            return nextState;
        case REWIND_PROGRESS:
            if (state.playerReference)
                state.playerReference.seekTo(
                    state.playerReference.getCurrentTime() - FAST_PROGRESS_INCREMENT,
                    "seconds"
                );
            return nextState;
        case START_DRAGGING:
            nextState.dragging = true;
            return nextState;
        case STOP_DRAGGING:
            if (state.playerReference) state.playerReference.seekTo(action.payload);
            nextState.dragging = false;
            return nextState;
        case TOGGLE_FULLSCREEN:
            nextState.isFullScreen = !state.isFullScreen;
            return nextState;
        case HANDLE_PROGRESS:
            if (!state.dragging) nextState.sliderTimeValue = action.payload.played;
            nextState.currentTime = action.payload.playedSeconds;
            return nextState;
        case HANDLE_TOGGLE_PLAY:
            console.log(action);
            nextState.isPlaying = !nextState.isPlaying;
            if (nextState.isPlaying) {
                action?.emitter(PLAY);
            } else {
                action?.emitter(PAUSE);
            }
            return nextState;
        case HANDLE_STOP:
            nextState.isPlaying = false;
            action?.emitter(ENDED);
            return nextState;
        default:
            throw new Error();
    }
};

const MediaPlayer = ({ mediaUrl, mediaThumbnailUrl, mediaType, isSlim, isCompact }: MediaPlayerProps) => {
    const initialState: MediaPlayerState = {
        mediaPlayerId: uuidv4(),
        mediaUrl,
        mediaThumbnailUrl,
        mediaType,
        playerReference: null,
        isPlayerReady: false,
        isHLSJSLoaded: false,
        isPlaying: false,
        isBuffering: false,
        displayPreview: mediaType === VIDEO_MEDIA_TYPE,
        currentTime: 0,
        sliderTimeValue: 0,
        playbackSpeed: 1,
        duration: 0,
        dragging: false,
        volume: 1,
        previousVolume: 0,
        isMuted: false,
        isFullScreen: false,
        isCompact: isCompact || false,
    };

    const [mediaPlayerState, dispatch] = useReducer(reducer, initialState);
    const fullScreenHandle = useFullScreenHandle();
    const { fireMetricsEvent } = useMetricsEmitter(mediaPlayerState);

    const handleFullScreenToggle = async () =>
        (await mediaPlayerState.isFullScreen) ? fullScreenHandle.exit() : fullScreenHandle.enter();

    const timePlayedDisplayValue = (value: number) => formatSeconds(value * mediaPlayerState.duration);

    const mediaPlayerWrapperStyles = () =>
        classNames({
            "relative flex flex-col bg-black min-h-5 w-full": true,
            "mb-1_5": !isCompact,
            "aspect-[16/9]": mediaThumbnailUrl && !(isSlim || isCompact),
        });

    return (
        <FullScreen handle={fullScreenHandle} onChange={() => dispatch({ type: TOGGLE_FULLSCREEN })}>
            <div id={`media_player_${mediaPlayerState.mediaPlayerId}`} className={mediaPlayerWrapperStyles()}>
                {mediaType === AUDIO_MEDIA_TYPE && !isSlim && mediaThumbnailUrl ? (
                    <Thumbnail thumbnailSource={mediaThumbnailUrl} />
                ) : null}
                <ReactPlayer
                    url={mediaUrl}
                    width="100%"
                    height="100%"
                    playsinline
                    playing={mediaPlayerState.isPlaying}
                    playbackRate={mediaPlayerState.playbackSpeed}
                    volume={mediaPlayerState.volume}
                    muted={mediaPlayerState.isMuted}
                    onReady={(player) => dispatch({ type: SET_IS_PLAYER_READY, payload: player })}
                    onDuration={(duration: number) => dispatch({ type: SET_DURATION, payload: duration })}
                    onProgress={(progress) => dispatch({ type: HANDLE_PROGRESS, payload: { ...progress } })}
                    onBuffer={() => dispatch({ type: SET_IS_BUFFERING, payload: true })}
                    onBufferEnd={() => dispatch({ type: SET_IS_BUFFERING, payload: false })}
                    onEnded={() => dispatch({ type: HANDLE_STOP, emitter: fireMetricsEvent })}
                    onError={(error: any) => fireMetricsEvent(ERROR, error)}
                    config={mediaPlayerConfigs[mediaType]}
                />
                <Preview
                    isReady={mediaPlayerState.isPlayerReady}
                    displayPreview={mediaPlayerState.displayPreview}
                    mediaThumbnailUrl={mediaThumbnailUrl}
                    fireMetricsEvent={fireMetricsEvent}
                    dispatch={dispatch}
                />
                <ControlBar
                    mediaPlayerId={mediaPlayerState.mediaPlayerId}
                    isPlaying={mediaPlayerState.isPlaying}
                    isBuffering={mediaPlayerState.isBuffering}
                    displayPreview={mediaPlayerState.displayPreview}
                    isCompact={mediaPlayerState.isCompact}
                    isSlim={isSlim}
                    currentTime={mediaPlayerState.currentTime}
                    duration={mediaPlayerState.duration}
                    playbackSpeed={mediaPlayerState.playbackSpeed}
                    volume={mediaPlayerState.volume}
                    isMuted={mediaPlayerState.isMuted}
                    sliderTimeValue={mediaPlayerState.sliderTimeValue}
                    sliderMinValue={MIN_SLIDER_VALUE}
                    sliderMaxValue={MAX_SLIDER_VALUE}
                    sliderStepSize={SLIDER_INCREMENT_VALUE}
                    onSlideStart={() => dispatch({ type: START_DRAGGING })}
                    onSlideEnd={(timeFraction: number) => dispatch({ type: STOP_DRAGGING, payload: timeFraction })}
                    getDisplayValue={timePlayedDisplayValue}
                    isFullScreen={mediaPlayerState.isFullScreen}
                    onFullScreenButtonClick={handleFullScreenToggle}
                    shouldShowOverlay={mediaType === VIDEO_MEDIA_TYPE || (mediaType === AUDIO_MEDIA_TYPE && !isSlim)}
                    mediaType={mediaType}
                    fireMetricsEvent={fireMetricsEvent}
                    dispatch={dispatch}
                />
            </div>
        </FullScreen>
    );
};

export default MediaPlayer;
