import { Accordion, AccordionPanel } from "@gs-ux-uitoolkit-react/accordion";

type DialInDetailsProps = {
    phoneNumber: string;
};
const DialInDetails = ({ phoneNumber }: DialInDetailsProps) => (
    <Accordion size="sm" emphasis="bold" className="pt-px font-normal leading-5 mb-0_5">
        <AccordionPanel header="Dial-in details">{phoneNumber}</AccordionPanel>
    </Accordion>
);

export default DialInDetails;
