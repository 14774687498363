import React from "react";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import { DomElement } from "htmlparser2";
import type { MarginNote } from "@forge-cms/models/components";

export type MarginNotesProps = {
    marginNotes?: MarginNote[];
};

type TransformNode = (node: DomElement, index: number, className: string) => React.ReactElement | void | null;

const transformNode: TransformNode = (node, index, className) => {
    if (node.attribs && node.type === "tag" && node.name === "p") {
        // eslint-disable-next-line no-param-reassign
        node.attribs.class = className;

        return convertNodeToElement(node, index, (childNode, childIndex) =>
            transformNode(childNode, childIndex, className)
        );
    }
    // returning undefined signals the parser to continue iterating through nodes
    return undefined;
};

const decodeAndParse = (text: string, className: string) => {
    const parsedHtml = Buffer.from(text, "base64").toString("utf-8");
    const parsedMarginNotes = ReactHtmlParser(parsedHtml, {
        transform: (node, index) => transformNode(node, index, className),
    });

    return parsedMarginNotes;
};

const MarginNotes: React.FC<MarginNotesProps> = ({ marginNotes }: MarginNotesProps) => (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
        {marginNotes && marginNotes.length > 0
            ? marginNotes.map((marginNote, index) => (
                  <div
                      className="font-sans font-light text-[0.71875em] border-l-2 border-scheme-primary border-solid border-opacity-100 my-2_5"
                      data-testid={`MarginNote-${index}`}>
                      {decodeAndParse(marginNote.text, "pl-2 mb-5")}
                  </div>
              ))
            : null}
    </>
);

export default MarginNotes;
