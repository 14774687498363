// "use client";

import React from "react";
import { ForgeIcon } from "@forge-ui-components/atoms";

export type ShareActionProps = {
    share: {
        label: string;
        emails?: string[];
        subject: string;
        body: string;
    };
    className?: string;
};

type ComposeMailToType = {
    emails?: string[];
    subject: string;
    body: string;
};

const baseStyles =
    "w-max font-sans text-sm text-black visited:text-black hover:text-black leading-normal flex items-center";

const composeMailTo = ({ emails, subject, body }: ComposeMailToType): string => {
    const recipients = emails && emails.length ? emails.join(",") : "";
    return `mailto:${recipients}?Subject=${subject}&body=${body}.html`;
};
const ShareAction: React.FC<ShareActionProps> = ({ share, className }) => {
    const { label, emails, subject, body } = share;
    const encodedBody = encodeURIComponent(body);
    const encodedSubject = encodeURIComponent(subject);
    return (
        <a
            href={composeMailTo({ emails, subject: encodedSubject, body: encodedBody })}
            className={`${baseStyles} ${className || null}`}>
            <span>{label}</span>
            <span className="pl-0_5">
                <ForgeIcon icon="share" pxSize={20} color="#000000" />
            </span>
        </a>
    );
};

export default ShareAction;
