"use client";

import { ModalPlacement } from "@gs-ux-uitoolkit-react/modal";
import { useContext } from "react";
import { ReportTemplateContext } from "@forge-ui-components/context-providers";

import { Exhibit } from "@forge-cms/models/components";
import dynamic from "next/dynamic";
import ExhibitGalleryThumbnail from "./ExhibitGalleryThumbnail.tsx";
import type { SlideModalProps } from "./modal/SlideModal.tsx";
import type { SlideModalHeaderProps } from "./modal/SlideModalHeader.tsx";
import type { SlideModalBodyProps } from "./modal/SlideModalBody.tsx";
import type { SlideModalFooterProps, ZoomProps } from "./modal/footer/SlideModalFooter.tsx";
import { scrollToElement } from "./utils.ts";

/** These components cannot be rendered server side */
/* eslint-disable */
const SlideModal = dynamic<SlideModalProps>(() => import("./modal/SlideModal"), { ssr: false });
const SlideModalHeader = dynamic<SlideModalHeaderProps>(() => import("./modal/SlideModalHeader"), { ssr: false });
const SlideModalBody = dynamic<SlideModalBodyProps>(() => import("./modal/SlideModalBody"), { ssr: false });
const SlideModalFooter = dynamic<SlideModalFooterProps>(() => import("./modal/footer/SlideModalFooter"), {
    ssr: false,
});
/* eslint-disable */

const EXHIBIT_IMAGE_EXTENSION = ".img.1280.high.png";

type ExhibitItem = {
    /**
     * Exhibit properties
     */
    exhibit: Exhibit;
    /**
     * The index corresponding to the order in which the exhibit shows on the page
     */
    index: number;
};

type ExhibitGalleryProps = {
    /**
     * The distribution headline of the current report the gallery is being displayed under
     */
    distributionHeadline: string;
    /**
     * The URL of the current report the gallery is being displayed under
     */
    reportLink: string;
    /**
     * Placement of the modal on the screen when it is open
     */
    placement: ModalPlacement;
    /**
     * Map of exhibitId to exhibit
     */
    exhibitMap: Map<string, ExhibitItem>;
    /**
     * Configures the zooming capabilities on the gallery
     */
    zoomProps: ZoomProps;
    /**
     * Localised title used for the Exhibit Gallery thumbnail
     */
    galleryTitle: string;
    /**
     * Localised label for "View All"
     */
    viewAllLabel: string;
};

const ExhibitGallery = ({
    distributionHeadline,
    reportLink,
    placement,
    exhibitMap,
    zoomProps,
    galleryTitle,
    viewAllLabel,
}: ExhibitGalleryProps) => {
    const { reportTemplateContextState, setReportTemplateContextState } = useContext(ReportTemplateContext);
    const maxZoomLevel = 1 + zoomProps.maxZoomIns * zoomProps.zoomScaleStep;
    const exhibitItems = Array.from(exhibitMap.values());
    const exhibitIndex =
        (reportTemplateContextState?.currentExhibitId &&
            exhibitMap.get(reportTemplateContextState.currentExhibitId)?.index) ||
        0;

    const setZoomLevel = (galleryZoomLevel: number) => {
        setReportTemplateContextState({
            ...reportTemplateContextState,
            galleryZoomLevel,
        });
    };

    const setCurrentExhibitIndex = (index: number) => {
        setReportTemplateContextState({
            ...reportTemplateContextState,
            currentExhibitId: exhibitItems[index].exhibit.referenceId,
            galleryZoomLevel: 1,
        });
    };

    const toggleVisibility = () => {
        reportTemplateContextState &&
            setReportTemplateContextState({
                ...reportTemplateContextState,
                galleryZoomLevel: 1,
                galleryOpen: !reportTemplateContextState.galleryOpen,
            });
    };

    const viewInContextHandler = (id: string) => {
        toggleVisibility();
        setTimeout(() => {
            if (id.startsWith("#")) {
                const elementId = id.substring(1);
                scrollToElement(elementId);
            }
            scrollToElement(id);
        }, 300);
    };

    return (
        <>
            <ExhibitGalleryThumbnail
                openGallery={toggleVisibility}
                exhibitCount={exhibitMap.size}
                exhibitThumbnail={`${exhibitItems[0].exhibit.assetUrl}.img.png`}
                galleryTitle={galleryTitle}
                viewAllLabel={viewAllLabel}
            />
            {reportTemplateContextState && (
                <SlideModal open={reportTemplateContextState.galleryOpen} placement={placement}>
                    <SlideModalHeader
                        emailHref={`mailto:?subject=GS Research: ${distributionHeadline}&body=${distributionHeadline} : ${reportLink}`}
                        printImageSrc={`${exhibitItems[exhibitIndex].exhibit.assetUrl}.img.png`}
                        onDismiss={toggleVisibility}
                    />

                    {/* Non-mobile */}
                    <SlideModalBody
                        exhibitIndex={exhibitIndex}
                        viewInContextHandler={viewInContextHandler}
                        currentSlideHref={`#${exhibitItems[exhibitIndex].exhibit.referenceId}`}
                        className="hidden h-[calc(100%-14rem)] md:flex md:flex-col md:grow"
                        contentClassName="grow shrink basis-auto"
                        draggingEnabled
                        title={exhibitItems[exhibitIndex].exhibit.title || ""}
                        description={exhibitItems[exhibitIndex].exhibit.description || ""}
                        note={exhibitItems[exhibitIndex].exhibit.text || ""}
                        scale={reportTemplateContextState.galleryZoomLevel * 100}
                        imageSrc={`${exhibitItems[exhibitIndex].exhibit.assetUrl}${EXHIBIT_IMAGE_EXTENSION}`}
                    />

                    {/* Mobile */}
                    {exhibitItems.map((exhibitItem) => (
                        <SlideModalBody
                            exhibitIndex={exhibitItem.index}
                            className="block md:hidden"
                            draggingEnabled={false}
                            title={exhibitItem.exhibit.title || ""}
                            description={exhibitItem.exhibit.description || ""}
                            note={exhibitItem.exhibit.text || ""}
                            contentClassName="h-full"
                            scale={100}
                            viewInContextHandler={viewInContextHandler}
                            currentSlideHref={`#${exhibitItems[exhibitIndex].exhibit.referenceId}`}
                            imageSrc={`${exhibitItem.exhibit.assetUrl}${EXHIBIT_IMAGE_EXTENSION}`}
                        />
                    ))}

                    <SlideModalFooter
                        zoomLevel={reportTemplateContextState.galleryZoomLevel}
                        zoomProps={zoomProps}
                        slideCount={exhibitItems.length}
                        className="hidden h-24 border-t border-solid border-t-gray-subtle p-4 md:block"
                        currentSlideIndex={exhibitIndex}
                        currentSlideHref={`#${exhibitItems[exhibitIndex].exhibit.referenceId}`}
                        onUpdateSlideIndex={setCurrentExhibitIndex}
                        onZoomIncrease={() =>
                            reportTemplateContextState.galleryZoomLevel < maxZoomLevel &&
                            setZoomLevel(reportTemplateContextState.galleryZoomLevel + zoomProps.zoomScaleStep)
                        }
                        onZoomDecrease={() =>
                            reportTemplateContextState.galleryZoomLevel > 1 &&
                            setZoomLevel(reportTemplateContextState.galleryZoomLevel - zoomProps.zoomScaleStep)
                        }
                        viewInContextHandler={viewInContextHandler}
                    />
                </SlideModal>
            )}
        </>
    );
};

export type { ExhibitGalleryProps, ExhibitItem, ZoomProps };
export default ExhibitGallery;
