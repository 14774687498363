import React from "react";
import { ForgeIcon } from "@forge-ui-components/atoms";

export type PdfActionProps = {
    href: string;
    label: string;
    className?: string;
};

const baseStyles = "w-max font-sans text-sm text-black visited:text-black hover:text-black flex items-center";

const PdfAction: React.FC<PdfActionProps> = ({ href, label, className }) => (
    <a href={href} target="_blank" rel="noreferrer" className={`${baseStyles} ${className || null}`}>
        <span>{label}</span>
        <span className="pl-0_5">
            <ForgeIcon icon="pdf" pxSize={20} color="#000000" />
        </span>
    </a>
);

export default PdfAction;
